import axios from "axios";
import config from "../utils/config";

export const checkInvite = async (email) => {
  const baseURL = config.API_URL;
  const path = "/invite/check";
  const res = await axios.post(baseURL + path, {
    email_id: email,
  });
  return res.data;
};
