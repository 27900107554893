import { Box, Button } from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import AddMember from "../../screens/team/AddMember";

const CustomDataGridToolbar = () => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        mb: 2,
        justifyContent: "space-between",
      }}
    >
      <GridToolbarQuickFilter
        sx={{
          width: "50%",
          "& .MuiInputBase-root": {
            padding: "10px", // Adjust padding for the input box
            borderRadius: "6px", // Rounded corners
          },
          "& .MuiOutlinedInput-input": {
            padding: "12px", // Adjust internal text padding
          },
        }}
      />
      <AddMember />
    </Box>
  );
};

export default CustomDataGridToolbar;
