import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const getSupervisorAnalysisAggregatedReport = async (therapistId) => {
  const baseURL = config.API_URL;
  const path = "/supervisor/analysis/aggregated_report";
  const params = "?therapist_id=" + therapistId;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const getCompatibilityScoreByClient = async (therapistId) => {
  const baseURL = config.API_URL;
  const path = "/supervisor/analysis/compatibility_score_by_client";
  const params = "?therapist_id=" + therapistId;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const getSupervisorTeamMembers = async (therapistId) => {
  const baseURL = config.API_URL;
  const path = "/supervisor/team_members";
  const params = "?therapist_id=" + therapistId;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};
