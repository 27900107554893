// interface Client {
//   name: string;
//   since: string;
//   lastSession: string;
//   email: string;
//   concerns: string[];
//   notes: string;
// }

// interface Note {
//   date: string;
//   text: string;
// }

// interface Session {
//   date: string;
//   type: "zoom" | "realtime";
//   duration: string;
//   topics: string[];
//   status: string;
//   recording: boolean;
// }

// interface RealtimeSessionProps {
//   isOpen: boolean;
//   onClose: () => void;
//   clientName: string;
//   sessionNumber: number;
// }
import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/card";
import { Button } from "../../components/button";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/tabs";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/dialog";
import { Textarea } from "../../components/textarea";
import {
  Mail,
  Calendar,
  Clock,
  Video,
  FileText,
  Brain,
  ExternalLink,
  Plus,
  MessageCircle,
  BarChart2,
  AlertTriangle,
  Target,
  Laptop,
  Mic,
  Play,
  Users,
} from "lucide-react";
import RealtimeSession from "./RealtimeSession";
import ZoomSessionDialog from "./ZoomSessionDialog";
import { useNavigate } from "react-router-dom";
import {
  getPatientDetailsByTherapyId,
  getTherapyConcernsByTherapyId,
} from "../../api/therapist";
import {
  capitalizeFirstLetter,
  mysqlDatetimeToJavascriptDatetime,
} from "../../utils/helpers";
import { useSelector } from "react-redux";
import { differenceInMinutes, format } from "date-fns";
import ClientConcernsModal from "./ClientConcernModal";
import NotesTabPanel from "./NotesTabPanel";
import SessionTypeIcon from "./SessionIcon";
import SessionTypeLabel from "./SessionTypeLabel";
import TranscriptPlayer from "./TranscriptPlayer";
import Loader from "../../components/loader/Loader";

const ClientDetailsPage = () => {
  const [activeTab, setActiveTab] = useState("prep");
  const [showRecordingDialog, setShowRecordingDialog] = useState(false);
  const [isSessionModalOpen, setIsSessionModalOpen] = useState(false);
  const [selectedRecording, setSelectedRecording] = useState(null);
  const [showZoomDialog, setShowZoomDialog] = useState(false);
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const therapy_id = queryParameters.get("therapy_id");
  const patient_id = queryParameters.get("patient_id");
  const therapist_id = useSelector((store) => store.therapist.therapist_id);

  const [loading, setLoading] = useState(true);
  const [patientDetails, setPatientDetails] = useState(null);
  const [patientConcerns, setPatientConcerns] = useState([]);
  const [notes, setNotes] = useState([]);
  const [showClientConcernsModal, setShowClientConcernsModal] = useState(false);

  // useEffect(() => {
  //   getPatientDetailsByTherapyId(patient_id, therapy_id).then(async (res) => {
  //     res.sessions.sort(
  //       (a, b) => new Date(b.start_time) - new Date(a.start_time)
  //     );
  //     res.sessions.forEach((session) =>
  //       session.transcripts.sort((a, b) => a.start - b.start)
  //     );
  //     console.log(res);
  //     setPatientDetails(res);

  //     const data = await fetchNotesByTherapyId(res["therapy"]["therapy_id"]);
  //     const sortedNotes = data.sort(
  //       (a, b) => new Date(b.created_at) - new Date(a.created_at)
  //     );
  //     setNotes(sortedNotes);

  //     getTherapyConcernsByTherapyId(therapist_id).then((res) => {
  //       setPatientConcerns(res.all_concerns_status);
  //     });
  //     setLoading(false);
  //   });
  // }, []);

  useEffect(() => {
    setLoading(true);

    Promise.all([
      getPatientDetailsByTherapyId(patient_id, therapy_id),
      // getPatientDetailsByTherapyId("dummy", "b16aa534-7601-4312-9abd-b41540106a38"),
      getTherapyConcernsByTherapyId(therapy_id),
    ])
      .then(([patientDetailsRes, therapyConcernsRes]) => {
        // console.log("patientDetailsRes", patientDetailsRes)
        patientDetailsRes.sessions.sort(
          (a, b) => new Date(b.start_time) - new Date(a.start_time)
        );

        patientDetailsRes.sessions.forEach((session) => {
          session.transcripts.sort((a, b) => a.start - b.start);
        });

        const allNotes = patientDetailsRes.sessions
          .filter((session) => session.summary)
          .map((session) => ({
            session_id: session.session_id,
            start_time: session.start_time,
            summary: session.summary,
          }));

        setPatientDetails(patientDetailsRes);
        setNotes(allNotes);
        setPatientConcerns(therapyConcernsRes.all_concerns_status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const parseToUtcDate = (datetimeStr) => {
    if (datetimeStr.includes("T") && datetimeStr.includes("Z")) {
      return new Date(datetimeStr);
    } else {
      return new Date(datetimeStr.replace(" ", "T") + "Z");
    }
  };

  const getTimeLeftLabel = (session) => {
    // const startTime = parseToUtcDate("2025-02-07 07:23:25");
    // const endTime = parseToUtcDate("2025-02-07 07:49:25");
    // const now = new Date("2025-02-07T08:10:25Z");

    const startTime = parseToUtcDate(session.start_time);
    const endTime = parseToUtcDate(session.end_time);
    const totalProcessingTime = differenceInMinutes(endTime, startTime) + 15; // 10 minutes buffer to star AWS batch

    const now = new Date();
    const elapsedSinceEnd = differenceInMinutes(now, endTime);
    const timeLeft = totalProcessingTime - elapsedSinceEnd;

    if (timeLeft <= 0) {
      return "Almost done.";
    } else {
      return `About ${timeLeft} min left.`;
    }
  };

  const patientConcernsFiltered = useMemo(
    () => patientConcerns.filter((c) => c.status === "1"),
    [patientConcerns]
  );

  if (loading) {
    return (
      <div className="p-6 max-w-7xl mx-auto space-y-6 h-full flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="p-6 max-w-7xl mx-auto space-y-6 w-full">
      <ClientConcernsModal
        show={showClientConcernsModal}
        onClose={() => setShowClientConcernsModal(false)}
        allConcerns={patientConcerns}
        setAllConcerns={setPatientConcerns}
        therapyId={therapy_id}
      />
      {/* Header */}
      <div className="flex justify-between items-start">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">
            {capitalizeFirstLetter(patientDetails.therapy.name)}
          </h1>
          <div className="flex gap-6 mt-2 text-gray-600">
            <div className="flex items-center gap-2">
              <Calendar className="h-4 w-4" />
              <span>
                Connected since:{" "}
                {patientDetails?.therapy?.created_at
                  ? format(
                      new Date(
                        mysqlDatetimeToJavascriptDatetime(
                          patientDetails.therapy.created_at
                        )
                      ),
                      "MMM d, yyyy"
                    )
                  : "NA"}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <Clock className="h-4 w-4" />
              <span>
                Last session:{" "}
                {patientDetails.sessions && patientDetails.sessions.length > 0
                  ? format(
                      new Date(
                        mysqlDatetimeToJavascriptDatetime(
                          patientDetails.sessions[0].start_time
                        )
                      ),
                      "MMM d, yyyy"
                    )
                  : "NA"}
              </span>
            </div>
            {/* <div className="flex items-center gap-2">
              <Mail className="h-4 w-4" />
              <span>{patientDetails.therapy.email}</span>
            </div> */}
          </div>
        </div>

        <div className="flex gap-3">
          <Button
            variant="outline"
            className="flex items-center gap-2"
            onClick={() =>
              navigate(
                `/analysis?therapistId=${therapist_id}&therapyId=${therapy_id}`
              )
            }
          >
            <BarChart2 className="h-4 w-4" />
            Analytics
          </Button>
          <Button
            variant="outline"
            className="flex items-center gap-2"
            onClick={() => setShowZoomDialog(true)}
          >
            <Video className="h-4 w-4" />
            Zoom Session
          </Button>
          <Button
            className="flex items-center gap-2"
            onClick={() => setIsSessionModalOpen(true)}
          >
            <MessageCircle className="h-4 w-4" />
            Start Realtime Session
          </Button>
        </div>
      </div>
      <div className="space-y-4 mb-6">
        <div className="flex gap-2 flex-wrap items-center">
          {patientConcernsFiltered.map((c) => (
            <span
              key={c.concern_id}
              className="px-3 py-1 bg-gray-50 text-gray-700 border border-gray-200 rounded-full text-sm"
            >
              {c.concern}
            </span>
          ))}
          {patientConcernsFiltered.length === 0 && "No concerns added"}
          <Button
            variant="ghost"
            size="sm"
            className="rounded-full h-8"
            onClick={() => {
              setShowClientConcernsModal(true);
            }}
          >
            <Plus className="h-4 w-4 text-gray-500" />
          </Button>
        </div>
      </div>
      {/* Main Tabs */}
      <Tabs
        defaultValue="activity"
        className="w-full"
        value={activeTab}
        onValueChange={setActiveTab}
      >
        <TabsList>
          <TabsTrigger value="prep">Preparation</TabsTrigger>
          <TabsTrigger value="activity">Activity</TabsTrigger>
          <TabsTrigger value="progress">Progress Notes</TabsTrigger>
          <TabsTrigger value="recordings">Recordings</TabsTrigger>
        </TabsList>

        {/* Activity Tab */}
        <TabsContent value="activity">
          <Card>
            <CardHeader>
              <CardTitle className="text-lg">Session History</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {patientDetails.sessions.map((session, i) => (
                  <div
                    key={i}
                    className={`flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50 transition-colors ${
                      session.session_status !== "PENDING"
                        ? "cursor-pointer"
                        : "cursor-not-allowed"
                    }`}
                    onClick={() => {
                      if (session.session_status === "PENDING") return;
                      navigate(
                        `/analysis?therapistId=${therapist_id}&therapyId=${therapy_id}&sessionId=${session.session_id}&sessionType=${session.session_type}`
                      );
                    }}
                  >
                    <div className="flex items-center gap-4">
                      <SessionTypeIcon type={session.session_type} />
                      <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-2">
                          <span className="font-medium">
                            {session.start_time
                              ? format(
                                  new Date(
                                    mysqlDatetimeToJavascriptDatetime(
                                      session.start_time
                                    )
                                  ),
                                  "MMM d, yyyy"
                                )
                              : "NA"}
                          </span>
                          <SessionTypeLabel type={session.session_type} />
                          {session.session_status === "PENDING" && (
                            <span className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-xs">
                              In Progress
                            </span>
                          )}
                        </div>
                        <span className="text-sm text-gray-600">
                          {differenceInMinutes(
                            new Date(
                              mysqlDatetimeToJavascriptDatetime(
                                session.end_time
                              )
                            ),
                            new Date(
                              mysqlDatetimeToJavascriptDatetime(
                                session.start_time
                              )
                            )
                          )}{" "}
                          mins
                        </span>
                        {/* {s.topics.length > 0 && (
                          <div className="flex gap-2 mt-1">
                            {s.topics.map((topic, idx) => (
                              <span
                                key={idx}
                                className="px-2 py-1 bg-gray-100 text-gray-700 rounded-full text-xs"
                              >
                                {topic}
                              </span>
                            ))}
                          </div>
                        )} */}
                      </div>
                    </div>
                    <Button
                      variant="ghost"
                      size="sm"
                      disabled={session.session_status === "PENDING"}
                      onClick={() => {
                        navigate(
                          `/analysis?therapistId=${therapist_id}&therapyId=${therapy_id}&sessionId=${session.session_id}&sessionType=${session.session_type}`
                        );
                      }}
                    >
                      {session.session_status === "PENDING"
                        ? `Processing. ${getTimeLeftLabel(session)}`
                        : "View Details"}
                    </Button>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        {/* Progress Notes Tab */}
        <TabsContent value="progress" className="space-y-4">
          <NotesTabPanel notes={notes} therapy_id={therapy_id} />
        </TabsContent>

        {/* Transcripts Tab */}
        <TabsContent value="recordings">
          <Card>
            <CardHeader>
              <CardTitle>Session Recordings</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {patientDetails.sessions.map((session, idx) => (
                  <>
                    {session.media_url && (
                      <div
                        key={idx}
                        onClick={() => {
                          console.log("session", session);
                          setSelectedRecording(session);
                          setShowRecordingDialog(true);
                        }}
                        className="flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50 cursor-pointer transition-colors"
                      >
                        <div className="flex items-center gap-4">
                          {session.session_type === "ZOOM" ? (
                            <Video className="h-5 w-5 text-purple-500" />
                          ) : (
                            <Mic className="h-5 w-5 text-blue-500" />
                          )}
                          <div>
                            <div className="flex items-center gap-2">
                              <span className="font-medium">
                                {session.start_time
                                  ? format(
                                      new Date(
                                        mysqlDatetimeToJavascriptDatetime(
                                          session.start_time
                                        )
                                      ),
                                      "MMM d, yyyy"
                                    )
                                  : "NA"}
                              </span>
                              <SessionTypeLabel type={session.session_type} />
                            </div>
                            <span className="text-sm text-gray-600">
                              {differenceInMinutes(
                                new Date(
                                  mysqlDatetimeToJavascriptDatetime(
                                    session.end_time
                                  )
                                ),
                                new Date(
                                  mysqlDatetimeToJavascriptDatetime(
                                    session.start_time
                                  )
                                )
                              )}{" "}
                              mins
                            </span>
                          </div>
                        </div>
                        <Button variant="ghost" size="sm">
                          <Play className="h-4 w-4 mr-2" />
                          Play Recording
                        </Button>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        {/* Preparation Tab */}
        <TabsContent value="prep">
          <div className="grid gap-6">
            <Card>
              <CardHeader>
                <CardTitle className="text-lg flex items-center gap-2">
                  <Brain className="h-5 w-5 text-blue-500" />
                  Plan For Next Session
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  {patientDetails.latest_plan
                    ? patientDetails.latest_plan
                    : "No Data to show here "}
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle className="text-lg flex items-center gap-2">
                  <AlertTriangle className="h-5 w-5 text-amber-500" />
                  Clinical Assessment
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  {patientDetails.latest_assessment
                    ? patientDetails.latest_assessment
                    : "No Data to show here "}
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle className="text-lg flex items-center gap-2">
                  <Target className="h-5 w-5 text-blue-500" />
                  Topic Suggestions
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  {patientDetails.topic_suggestions &&
                  patientDetails.topic_suggestions.length > 0
                    ? patientDetails.topic_suggestions.map((topics) => (
                        <>
                          <h3 className="font-medium mb-2">{topics.topic}</h3>
                          <ul className="space-y-2">
                            <li className="flex items-start gap-2">
                              <div className="h-2 w-2 rounded-full bg-blue-500 mt-2" />
                              <span>{topics.suggested_timing}</span>
                            </li>
                          </ul>
                        </>
                      ))
                    : "No Data to show here"}
                  {/* <div>
                    <h3 className="font-medium mb-2">Session Objectives</h3>
                    <ul className="space-y-2">
                      <li className="flex items-start gap-2">
                        <div className="h-2 w-2 rounded-full bg-blue-500 mt-2" />
                        <span>
                          Practice grounding technique for panic attacks
                        </span>
                      </li>
                      <li className="flex items-start gap-2">
                        <div className="h-2 w-2 rounded-full bg-blue-500 mt-2" />
                        <span>
                          Work on cognitive restructuring of work-related
                          thoughts
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3 className="font-medium mb-2">Therapeutic Approach</h3>
                    <ul className="space-y-2">
                      <li className="flex items-start gap-2">
                        <div className="h-2 w-2 rounded-full bg-blue-500 mt-2" />
                        <span>
                          Continue CBT with focus on automatic thoughts
                        </span>
                      </li>
                      <li className="flex items-start gap-2">
                        <div className="h-2 w-2 rounded-full bg-blue-500 mt-2" />
                        <span>
                          Consider introducing exposure hierarchy for work
                          anxiety
                        </span>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </CardContent>
            </Card>
          </div>
        </TabsContent>
      </Tabs>

      <ZoomSessionDialog
        therapy_id={therapy_id}
        isOpen={showZoomDialog}
        onClose={() => setShowZoomDialog(false)}
        onStartSession={() => {
          return new Promise(() => {});
        }}
      />

      {/* Recording Dialog */}
      {/* <Dialog open={showRecordingDialog} onOpenChange={setShowRecordingDialog}>
        <DialogContent className="max-w-4xl">
          <DialogHeader>
            <DialogTitle>
              Session Recording - {selectedRecording?.date}
            </DialogTitle>
          </DialogHeader>
          <div className="grid grid-cols-2 gap-6">
            <Card>
              <CardHeader>
                <CardTitle>Recording</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="aspect-video bg-gray-100 rounded-lg flex items-center justify-center">
                  {selectedRecording?.session_type === "ZOOM" ? (
                    <video
                      autoPlay
                      controls
                      className="h-full w-full text-gray-400"
                      src={
                        selectedRecording?.media_url
                          ? selectedRecording.media_url
                          : ""
                      }
                    ></video>
                  ) : (
                    <audio
                      autoPlay
                      controls
                      className="h-full w-full text-gray-400"
                      src={
                        selectedRecording?.media_url
                          ? selectedRecording.media_url
                          : ""
                      }
                    />
                  )}
                </div>
              </CardContent>
            </Card>

            <Card className="h-full flex flex-col max-h-[40rem]">
              <CardHeader>
                <CardTitle>Transcript</CardTitle>
              </CardHeader>
              <CardContent className="p-4 flex-1 overflow-y-auto ">
                <div className="h-full overflow-y-auto space-y-2">
                  {selectedRecording &&
                  selectedRecording.transcripts &&
                  selectedRecording.transcripts.length > 0 ? (
                    <div className="scroll-thin-vertical bg-white border border-gray-200 rounded-2xl p-4 overflow-x-hidden overflow-y-auto break-words flex flex-col gap-2 w-full h-full">
                      {selectedRecording.transcripts.map((item, index) => {
                        if (item.role.toLowerCase() === "therapist") {
                          return (
                            <div
                              key={index}
                              className="bg-gray-200 border border-gray-300 rounded-xl p-4 w-fit max-w-[90%] ml-auto"
                            >
                              <p className="text-indigo-900 font-semibold text-lg m-0">
                                You
                              </p>
                              <p className="m-0">{item.content}</p>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              key={index}
                              className="bg-yellow-200 border border-gray-300 rounded-xl p-4 w-fit max-w-[90%]"
                            >
                              <p className="text-indigo-900 font-semibold text-lg m-0">
                                Client
                              </p>
                              <p className="m-0">{item.content}</p>
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <>No transcripts</>
                  )}
                </div>
              </CardContent>
            </Card>
          </div>
        </DialogContent>
      </Dialog> */}
      {selectedRecording && (
        <TranscriptPlayer
          selectedRecording={selectedRecording}
          setShowRecordingDialog={setShowRecordingDialog}
          showRecordingDialog={showRecordingDialog}
          transcript={selectedRecording.transcripts}
        />
      )}

      <RealtimeSession
        isOpen={isSessionModalOpen}
        onClose={() => {
          setIsSessionModalOpen(false);
          getPatientDetailsByTherapyId(patient_id, therapy_id).then(
            (patientDetailsRes) => {
              patientDetailsRes.sessions.sort(
                (a, b) => new Date(b.start_time) - new Date(a.start_time)
              );

              patientDetailsRes.sessions.forEach((session) => {
                session.transcripts.sort((a, b) => a.start - b.start);
              });

              const allNotes = patientDetailsRes.sessions
                .filter((session) => session.summary)
                .map((session) => ({
                  session_id: session.session_id,
                  start_time: session.start_time,
                  summary: session.summary,
                }));

              setPatientDetails(patientDetailsRes);
              setNotes(allNotes);
            }
          );
        }}
        clientName={patientDetails?.therapy?.name}
        therapistId={therapist_id}
        therapyId={therapy_id}
      />
    </div>
  );
};

export default ClientDetailsPage;
