import React, { useEffect, useState } from "react";
import { X, Loader2, Save, Plus } from "lucide-react";
// import { toast } from "sonner";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/card";
import { Input } from "../../components/input";
import { Textarea } from "../../components/textarea";
import { Button } from "../../components/button";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { updateTherapistDetailsAPI } from "../../api/therapist";
import { updateTherapistSettings } from "../../store/therapist/therapistSlice";

const SettingsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const therapist = useSelector((store) => store.therapist);

  const insuranceOptions = [
    "Blue Cross Blue Shield",
    "Aetna",
    "UnitedHealthcare",
    "Cigna",
    "Medicare",
    "Medicaid",
  ];

  useEffect(() => {
    setSettings({
      name: therapist.name,
      bio: therapist.bio ?? "",
      designation: therapist.designation ?? "",
      qualifications: therapist.qualification ?? [],
      insurance: therapist.insurance ?? [],
    });
  }, [
    therapist.name,
    therapist.bio,
    therapist.designation,
    therapist.qualification,
    therapist.insurance,
  ]);

  const [settings, setSettings] = useState({
    name: therapist.name,
    bio: therapist.bio ?? "",
    designation: therapist.designation ?? "",
    qualifications: therapist.qualification ?? [],
    insurance: therapist.insurance ?? [],
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!settings.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!settings.designation.trim()) {
      newErrors.designation = "Designation is required";
    }

    if (!settings.bio.trim()) {
      newErrors.bio = "Bio is required";
    }

    if (settings.qualifications.some((q) => !q.trim())) {
      newErrors.qualifications = ["All qualifications must be filled"];
    }

    if (settings.insurance.some((q) => !q.trim())) {
      newErrors.insurance = ["Insurances covered by you needs to be added"];
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      toast("Please fill in all required fields", {
        description: "Some fields are missing or invalid",
        duration: 3000,
      });
      return;
    }

    setIsLoading(true);

    // Simulate API call
    try {
      await updateTherapistDetailsAPI(
        therapist.therapist_id,
        settings.designation,
        settings.qualifications,
        settings.bio,
        settings.insurance
      );
      dispatch(updateTherapistSettings(settings));
      toast("Settings saved successfully!", {
        description: "Your profile has been updated",
        duration: 3000,
      });
    } catch (error) {
      toast("Failed to save settings", {
        description: "Please try again later",
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const addQualification = () => {
    setSettings((prev) => {
      return {
        ...prev,
        qualifications: [...prev.qualifications, ""],
      };
    });
  };

  const removeQualification = (index) => {
    if (settings.qualifications.length > 1) {
      setSettings((prev) => ({
        ...prev,
        qualifications: prev.qualifications.filter((_, i) => i !== index),
      }));
    }
  };

  const updateQualification = (index, value) => {
    if (value === "") return;
    setSettings((prev) => ({
      ...prev,
      qualifications: prev.qualifications.map((q, i) =>
        i === index ? value : q
      ),
    }));
  };

  const addInsurance = (e) => {
    const value = e.target.value;
    if (value && !settings.insurance.includes(value)) {
      setSettings((prev) => {
        return {
          ...prev,
          insurance: [...prev.insurance, value],
        };
      });
    }
    e.target.value = "";
  };

  const removeInsurance = (insurance) => {
    setSettings({
      ...settings,
      insurance: settings.insurance.filter((item) => item !== insurance),
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6 w-full">
      <div className="max-w-3xl mx-auto space-y-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Settings</h1>
          <p className="text-gray-600 mt-1">Manage your profile settings</p>
        </div>

        <Card>
          <CardHeader>
            <CardTitle>Personal Information</CardTitle>
          </CardHeader>
          <CardContent className="space-y-6">
            {/* Name Field */}
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700">
                Name
                <span className="text-red-500 ml-1">*</span>
              </label>
              <Input
                placeholder="Enter your full name"
                value={settings.name}
                onChange={(e) =>
                  setSettings((prev) => ({ ...prev, name: e.target.value }))
                }
                className={errors.name ? "border-red-500" : ""}
                readOnly
              />
              {errors.name && (
                <p className="text-sm text-red-500">{errors.name}</p>
              )}
            </div>

            {/* Bio Field */}
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700">
                Bio
                <span className="text-red-500 ml-1">*</span>
              </label>
              <Textarea
                placeholder="Tell us about yourself"
                value={settings.bio}
                onChange={(e) =>
                  setSettings((prev) => ({ ...prev, bio: e.target.value }))
                }
                className={`min-h-[100px] ${
                  errors.bio ? "border-red-500" : ""
                }`}
              />
              {errors.bio && (
                <p className="text-sm text-red-500">{errors.bio}</p>
              )}
            </div>

            {/* Designation Field */}
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700">
                Designation
                <span className="text-red-500 ml-1">*</span>
              </label>
              <Input
                placeholder="Enter your designation"
                value={settings.designation}
                onChange={(e) =>
                  setSettings((prev) => ({
                    ...prev,
                    designation: e.target.value,
                  }))
                }
                className={errors.designation ? "border-red-500" : ""}
              />
              {errors.designation && (
                <p className="text-sm text-red-500">{errors.designation}</p>
              )}
            </div>

            {/* Qualifications Field */}
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700">
                Qualifications
                <span className="text-red-500 ml-1">*</span>
              </label>
              <div className="space-y-3">
                {settings.qualifications.map((qualification, index) => (
                  <div key={index} className="flex gap-2">
                    <Input
                      placeholder="Enter qualification"
                      value={qualification}
                      onChange={(e) =>
                        updateQualification(index, e.target.value)
                      }
                      className={errors.qualifications ? "border-red-500" : ""}
                    />
                    {settings.qualifications.length > 1 && (
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => removeQualification(index)}
                        className="shrink-0"
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    )}
                  </div>
                ))}
                <Button
                  variant="outline"
                  onClick={addQualification}
                  className="w-full border-dashed"
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add Qualification
                </Button>
                {errors.qualifications && (
                  <p className="text-sm text-red-500">
                    {errors.qualifications}
                  </p>
                )}
              </div>
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">
                Insurance Acceptance
                <span className="text-red-500 ml-1">*</span>
              </label>
              <div className="relative">
                <input
                  type="text"
                  list="insuranceOptions"
                  className="w-full px-3 py-2 border rounded-md cursor-pointer"
                  placeholder="Type to search insurance..."
                  onChange={addInsurance}
                />
                <datalist id="insuranceOptions">
                  {insuranceOptions.map((option, index) => (
                    <option key={index} value={option} />
                  ))}
                </datalist>
                <div className="flex flex-wrap gap-2 mt-2">
                  {settings.insurance.map((insurance, index) => (
                    <div
                      key={index}
                      className="flex items-center gap-1 bg-blue-50 text-blue-700 px-2 py-1 rounded-md"
                    >
                      <span className="text-sm">{insurance}</span>
                      <button
                        type="button"
                        className="text-blue-500 hover:text-blue-700"
                        onClick={() => removeInsurance(insurance)}
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              {errors.insurance && (
                <p className="text-sm text-red-500">{errors.insurance}</p>
              )}
            </div>

            {/* Save Button */}
            <div className="pt-4">
              <Button
                onClick={handleSave}
                className="w-full sm:w-auto"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                    Saving...
                  </>
                ) : (
                  <>
                    <Save className="h-4 w-4 mr-2" />
                    Save Changes
                  </>
                )}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default SettingsPage;
