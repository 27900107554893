import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const sendInviteUsingEmailAPI = async (therapist_id, patient_email) => {
  const baseURL = config.API_URL;
  const path = "/therapist/therapy";
  const body = {
    therapist_id,
    patient_email,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.post(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

export const createClientUsingNameAPI = async (client_name) => {
  const baseURL = config.API_URL;
  const path = "/therapist/create_client";
  const body = {
    client_name,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.post(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

export const sendInviteUsingEmailByTherapistAPI = async (
  patient_id,
  patient_email,
  patient_old_email
) => {
  const baseURL = config.API_URL;
  const path = "/therapist/client_invite";
  const body = {
    patient_id: patient_id,
    email: patient_email,
    old_email: patient_old_email,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.post(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

export const getPatientByNameAPI = async (therapistId, patient_name_query) => {
  const baseURL = config.API_URL;
  const path = "/therapist/patient/search";
  const params =
    "?patient_name_query=" +
    encodeURIComponent(patient_name_query) +
    "&therapist_id=" +
    therapistId;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

export const createSessionAPI = async (
  therapistEmail,
  therapistZoomId,
  therapyId,
  patientId,
  patientEmail,
  sessionStartDateTime,
  sessionEndDateTime,
  description
) => {
  const baseURL = config.API_URL;
  const path = "/therapist/sessions";

  const body = {
    therapist_email: therapistEmail,
    therapist_zoom_id: therapistZoomId,
    therapy_id: therapyId,
    patient_id: patientId,
    patient_email: patientEmail,
    session_start_date_time: sessionStartDateTime,
    session_end_date_time: sessionEndDateTime,
    description: description,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.post(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

export const getSessionsAPI = async (therapistId) => {
  const baseURL = config.API_URL;
  const path = "/therapist/sessions";
  const params = "?therapist_id=" + therapistId;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

export const deleteTherapySessionAPI = async (sessionId, meetingId) => {
  const baseURL = config.API_URL;
  const path = "/therapist/sessions";
  const params = "?session_id=" + sessionId + "&meeting_id=" + meetingId;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.delete(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

// export const getTherapistByEmailAPI = async (therapistEmail) => {
//   const baseURL = config.API_URL;
//   const path = "/therapist";
//   const params = "?therapist_email=" + therapistEmail;

//   const data = await axios.get(`${baseURL + path + params}`);

//   return data;
// };

export const getTherapistDashboardByEmailAPI = async (therapistEmail) => {
  const baseURL = config.API_URL;
  const path = "/therapist/dashboard";
  const params = "?therapist_email=" + encodeURIComponent(therapistEmail);
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

export const getTherapistByEmailIdAPI = async (therapistEmail) => {
  const baseURL = config.API_URL;
  const path = `/therapist?therapist_email=${encodeURIComponent(
    therapistEmail
  )}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(`${baseURL + path}`, {
    headers: {
      Authorization: idToken,
    },
  });

  return res.data;
};

export const updateTherapistAPI = async (therapist_id, body) => {
  const baseURL = config.API_URL;
  const path = `/therapist?therapist_id=${encodeURIComponent(therapist_id)}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.put(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const getPatientById = async (patient_id) => {
  const baseURL = config.API_URL;
  const path = "/patient/id";
  const params = "?patient_id=" + patient_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const getPatientDetailsByTherapyId = async (patient_id, therapy_id) => {
  const baseURL = config.API_URL;
  const path = "/v2/therapist/patient_details";
  const params = "?patient_id=" + patient_id + "&therapy_id=" + therapy_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const getTherapistClientsByName = async (
  client_name_query,
  therapist_id
) => {
  const baseURL = config.API_URL;
  const path = "/therapist/client/search";
  const params =
    "?client_name_query=" + client_name_query + "&therapist_id=" + therapist_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const getTherapyNotesByTherapyId = async (therapy_id) => {
  const baseURL = config.API_URL;
  const path = "/concern_notes";
  const params = "?therapy_id=" + therapy_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const postTherapyNote = async (therapy_id, note) => {
  const baseURL = config.API_URL;
  const path = "/concern_notes";
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.post(
    `${baseURL + path}`,
    JSON.stringify({ therapy_id, note }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
    }
  );
  console.log("fnjkndfjkgndfjk");
  console.log(res.data);
  return res.data;
};

export const updateTherapyNoteById = async (concern_note_id, note) => {
  const baseURL = config.API_URL;
  const path = "/concern_notes";
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.put(
    `${baseURL + path}`,
    JSON.stringify({ concern_note_id, note }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
    }
  );
  return res.data;
};

export const deleteTherapyNoteById = async (concern_note_id) => {
  const baseURL = config.API_URL;
  const path = "/concern_notes";
  const params = "?concern_note_id=" + concern_note_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.delete(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const getTherapyConcernsByTherapyId = async (therapy_id) => {
  const baseURL = config.API_URL;
  const path = "/concerns";
  const params = "?therapy_id=" + therapy_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const updateTherapyConcernsByTherapyId = async (
  all_concerns_status,
  therapy_id
) => {
  const baseURL = config.API_URL;
  const path = "/concerns";
  const headers = {
    "Content-Type": "application/json",
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.put(
    `${baseURL + path}`,
    JSON.stringify({ therapy_id, all_concerns_status }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
    }
  );
  return res.data;
};

export const getLatestTherapyTasksByTherapyId = async (therapy_id) => {
  const baseURL = config.API_URL;
  const path = "/therapy_tasks/latest";
  const params = "?therapy_id=" + therapy_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const getAllTherapyTasksByTherapyId = async (therapy_id) => {
  const baseURL = config.API_URL;
  const path = "/therapy_tasks";
  const params = "?therapy_id=" + therapy_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const postTherapyTaskByTherapyId = async (
  therapy_id,
  task,
  therapistName
) => {
  const baseURL = config.API_URL;
  const path = "/therapy_tasks";
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.post(
    `${baseURL + path}`,
    JSON.stringify({ therapy_id, task, therapist_name: therapistName }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
    }
  );
  return res.data;
};

export const updateTaskStatusById = async (task_id, status) => {
  console.log(task_id, status);
  const baseURL = config.API_URL;
  const path = "/therapy_tasks/status";
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.put(
    `${baseURL + path}`,
    JSON.stringify({ task_id, status }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
    }
  );
  return res.data;
};

export const deleteTaskById = async (task_id, authorName) => {
  console.log(task_id);
  const baseURL = config.API_URL;
  const path = `/therapy_tasks?task_id=${task_id}&author_name=${authorName}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.delete(`${baseURL + path}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const updateTherapistDetailsAPI = async (
  therapistId,
  designation,
  qualifications,
  bio,
  insurance
) => {
  const baseURL = config.API_URL;
  const path = "/therapist";
  const body = {
    therapistId,
    designation,
    qualifications,
    bio,
    insurance
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.patch(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const getTherapistProfilePictureUpdatePresignedUrlAPI = async (
  therapistId,
  extension
) => {
  const baseURL = config.API_URL;
  const path = "/therapist/profile_picture/presigned_url";
  const params = "?therapist_id=" + therapistId + "&extension=" + extension;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const updateTherapistProfilePictureAPI = async (email, bucket, key) => {
  const baseURL = config.API_URL;
  const path = "/therapist/profile_picture";
  const body = {
    email,
    bucket,
    key,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.patch(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const getTherapistReport = async (video_id) => {
  const baseURL = config.API_URL;
  const path = "/meetings/therapist_report";
  const params = "?video_id=" + encodeURIComponent(video_id);
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const getTherapistSessions = async (therapist_id) => {
  const baseURL = config.API_URL;
  const path = "/therapist/sessions-all";
  const params = "?therapist_id=" + encodeURIComponent(therapist_id);
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  console.log(`${baseURL + path + params}`);
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const getSupervisorOverview = async (therapist_id) => {
  const baseURL = config.API_URL;
  const path = "/therapist/supervisor/overview";
  const params = "?therapist_id=" + therapist_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  try {
    const res = await axios.get(`${baseURL + path + params}`, {
      headers: {
        Authorization: idToken,
      },
    });

    return res.data;
  } catch (err) {}
};

export const updateTherapistStatusAPI = async (therapist_id, new_status) => {
  const baseURL = config.API_URL;
  const path = "/therapist/update_status";
  const body = {
    therapist_id,
    new_status,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.post(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};
