import "./App.css";

import MindlyTherapistApp from "./apps/MindlyTherapistApp";

const MindlyApp = () => {
  return (
    <div className="app">
      <MindlyTherapistApp />
      {/* <AudioRecoder /> */}
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <MindlyApp />
    </div>
  );
}

export default App;
