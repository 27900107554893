import { useEffect, useRef, useState } from "react";
import NoClientAdded from "../clientInfo/noClientAdded";
import "./clientsSearch.css";
import AddClientButton from "../clientInfo/addClientButton";
import ClientInfoTile from "../clientInfo/clientInfoTile";
import InviteClient from "../../../../components/inviteClientModal/InviteClientModal";
import AddClientManually from "../../../../components/addClientManuallyModal/AddClientManuallyModal";
import CreateClientByNameModal from "../../../../components/createClientByNameModal/CreateClientByNameModal";
import ASSETS from "../../../../assets/Assets";
import "./searchBar.css";
import Button from "@mui/material/Button";
import {
  getTherapistClientsByName,
  getTherapistSessions,
} from "../../../../api/therapist";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { CSVLink } from "react-csv";
import ClientMetrics from "../clientMetrics/ClientMetrics";

const csvHeader = [
  { label: "Therapist Name", key: "therapist_name" },
  { label: "Meeting Status", key: "video_status" },
  { label: "Patient Name", key: "patient_name" },
  { label: "Start Date", key: "session_time" },
  { label: "Description", key: "session_description" },
];

const ClientList = ({ loading, patients }) => {
  return (
    <div className="w-full">
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="spinner">Loading...</div>{" "}
          {/* Replace with your spinner */}
        </div>
      ) : patients.length === 0 ? (
        <div style={{ width: "100%", height: "100%" }}>
          <NoClientAdded />
        </div>
      ) : (
        <div className="space-y-6 w-full">
          <div className="flex justify-between items-center mb-6 mt-6">
            <h2 className="text-xl font-bold text-gray-900">
              My Personal clients
            </h2>
          </div>
          <div className="grid grid-cols-2 gap-6 w-full">
            {patients.map((result, idx) => (
              <ClientInfoTile
                key={idx}
                last_session_date={result.last_session}
                next_session_date={result.next_session}
                risk_level={result.risk_level}
                status={result.therapy_status}
                therapy_id={result.therapy_id}
                patient_id={result.patient_id}
                name={result.patient_name}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

function ClientsSearch() {
  const [showInviteClientModal, setShowInviteClientModal] = useState(false);
  const [showAddClientManuallyModal, setShowAddeClientManuallyModal] =
    useState(false);
  const [csvData, setCsvData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchBarRef = useRef();

  const therapist_id = useSelector((store) => {
    return store.therapist.therapist_id;
  });
  const { patients, loading } = useSelector((state) => state.clientDashboard);

  const clientNameQuery = searchParams.get("query") || "";

  useEffect(() => {
    if (clientNameQuery) searchBarRef.current.value = clientNameQuery;
  }, []);

  useEffect(() => {
    try {
      getTherapistSessions(therapist_id).then((response) => {
        if (response.status === 200) {
          let data = response.data;
          data = data.map((item) => {
            if (
              item.video_status.toUpperCase() === "ANALYZED" ||
              item.video_status.toUpperCase() === "ANALYZING"
            ) {
              item.video_status = "MEETING COMPLETED";
            } else {
              item.video_status = "MEETING MISSED";
            }

            const date = new Date(item.session_time);
            const options = {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              // second: "2-digit",
              hour12: false,
              // timeZone: "Asia/Tokyo",
              // timeZoneName: "short",
            };

            item.session_time = date.toLocaleString("en-US", options) + ", JST";
            return item;
          });
          setCsvData(data);
        } else {
          // throw new Error("Failed to fetch data");
          console.error("Failed to fetch the data");
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  // Sorting the patients by last_session date
  const sortedPatients = [...patients].sort((a, b) => {
    if (a.last_session === null && b.last_session === null) return 0;
    if (!a.last_session) return 1; // Move patients with no session to the bottom
    if (!b.last_session) return -1;
    return Date.parse(b.last_session) - Date.parse(a.last_session); // Sort by date, newest first
  });

  return (
    <div className="client-details">
      <CreateClientByNameModal
        show={showInviteClientModal}
        onClose={() => setShowInviteClientModal(false)}
        setShowAddClientManuallyModal={setShowAddeClientManuallyModal}
      />
      {/* <AddClientManually show={showAddClientManuallyModal} onClose={() => setShowAddeClientManuallyModal(false)} /> */}
      <div className="search-add-container">
        {/* <div className="search-bar">
          <img
            className="search-logo"
            src={ASSETS.searchLogo}
            alt="search logo"
          />
          <input
            type="text"
            className="search-field"
            placeholder="Search by name...."
            ref={searchBarRef}
          />
          <Button
            variant="text"
            onClick={searchTherapistClientByName}
            style={{ color: "#5F6CE1", marginRight: "0.5rem" }}
          >
            Search
          </Button>
        </div> */}
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <AddClientButton onClick={() => setShowInviteClientModal(true)} />
        </div>
      </div>
      <ClientMetrics />
      <ClientList loading={loading} patients={sortedPatients} />
    </div>
  );
}

export default ClientsSearch;
