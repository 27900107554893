import { useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/dialog";
import { mysqlDatetimeToJavascriptDatetime } from "../../utils/helpers";
import { format } from "date-fns";

const TranscriptPlayer = ({
  selectedRecording,
  transcript,
  showRecordingDialog,
  setShowRecordingDialog,
}) => {
  const mediaRef = useRef(null);
  const transcriptRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);

  // Find active transcript segment
  const activeSegment = transcript.find(
    (seg) => currentTime >= seg.start && currentTime <= seg.end
  );

  // Auto-scroll transcript
  useEffect(() => {
    if (activeSegment) {
      const activeElement = document.getElementById(
        `segment-${activeSegment.start}`
      );
      if (activeElement) {
        activeElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [activeSegment]);

  // Handle click to seek media
  const handleSeek = (time) => {
    if (mediaRef.current) {
      mediaRef.current.currentTime = time;
    }
  };

  return (
    <Dialog open={showRecordingDialog} onOpenChange={setShowRecordingDialog}>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle className="w-full !text-left">
            Session Recording -{" "}
            {selectedRecording?.start_time
              ? format(
                  new Date(
                    mysqlDatetimeToJavascriptDatetime(
                      selectedRecording.start_time
                    )
                  ),
                  "MMM d, yyyy"
                )
              : "NA"}
          </DialogTitle>
        </DialogHeader>

        <div className="grid grid-cols-2 gap-6">
          {/* Media Player */}
          <Card>
            <CardHeader>
              <CardTitle>Recording</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="aspect-video bg-gray-100 rounded-lg flex items-center justify-center">
                {selectedRecording?.session_type === "ZOOM" ? (
                  <video
                    ref={mediaRef}
                    autoPlay
                    controls
                    className="h-full w-full text-gray-400"
                    src={selectedRecording?.media_url || ""}
                    onTimeUpdate={(e) =>
                      setCurrentTime(mediaRef.current.currentTime)
                    }
                  />
                ) : (
                  <audio
                    ref={mediaRef}
                    autoPlay
                    controls
                    className="h-full w-full text-gray-400"
                    src={selectedRecording?.media_url || ""}
                    onTimeUpdate={(e) => {
                      setCurrentTime(mediaRef.current.currentTime);
                    }}
                  />
                )}
              </div>
            </CardContent>
          </Card>

          {/* Transcript Section */}
          <Card>
            <CardHeader>
              <CardTitle>Transcript</CardTitle>
            </CardHeader>
            <CardContent>
              <div
                ref={transcriptRef}
                className="h-80 overflow-y-auto border p-2 space-y-2"
              >
                {transcript.map((seg, index) => {
                  if (seg.role.toLowerCase() === "therapist") {
                    return (
                      <div
                        key={index}
                        id={`segment-${seg.start}`}
                        className={` cursor-pointer  border border-gray-300 rounded-xl p-4 w-fit max-w-[90%] ml-auto ${
                          activeSegment?.start === seg.start
                            ? "bg-gray-300"
                            : "bg-blue-50"
                        }`}
                        onClick={() => handleSeek(seg.start)}
                      >
                        <p className="text-indigo-900 font-semibold text-lg m-0">
                          You
                        </p>
                        <p className="m-0">{seg.content}</p>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        id={`segment-${seg.start}`}
                        className={` border border-gray-300 rounded-xl p-4 w-fit max-w-[90%] cursor-pointer ${
                          activeSegment?.start === seg.start
                            ? "bg-gray-300"
                            : "bg-gray-100"
                        }`}
                        onClick={() => handleSeek(seg.start)}
                      >
                        <p className="text-indigo-900 font-semibold text-lg m-0">
                          Client
                        </p>
                        <p className="m-0">{seg.content}</p>
                      </div>
                    );
                  }
                })}
              </div>
            </CardContent>
          </Card>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TranscriptPlayer;
