import React from "react";

const SessionTypeLabel = ({ type }) => {
  const styles =
    type.toLowerCase() === "zoom"
      ? "bg-purple-100 text-purple-800"
      : "bg-blue-100 text-blue-800";
  return (
    <span className={`px-2 py-1 rounded-md text-xs font-medium ${styles}`}>
      {type.toLowerCase() === "zoom" ? "Zoom Session" : "In-person Session"}
    </span>
  );
};

export default SessionTypeLabel;
