import React from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/card";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  Legend,
  RadialBarChart,
  RadialBar,
  PolarAngleAxis,
} from "recharts";
import { toolTipText } from "../../../../utils/toolTipText";

const ClientMetrics = () => {
  // Get data from Redux store
  const {
    risk_summary,
    therapy_status,
    sessions_per_day,
    compatibilityScore,
    loading,
  } = useSelector((state) => state.clientDashboard);

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full">
        <p className="text-lg font-bold">Loading Metrics...</p>
      </div>
    );
  }

  // Risk Distribution Data
  const riskData = [
    { name: "High Risk", value: risk_summary.HIGH, color: "#EF4444" },
    { name: "Medium Risk", value: risk_summary.MEDIUM, color: "#F59E0B" },
    { name: "Low Risk", value: risk_summary.LOW, color: "#10B981" },
  ];

  // Client Status Data
  const statusData = [
    { status: "Active", count: therapy_status.ACTIVE },
    { status: "On Hold", count: therapy_status.ON_HOLD },
    { status: "Invited", count: therapy_status.INVITED },
    { status: "Completed", count: therapy_status.TERMINATED },
  ];

  // Weekly Trends Data
  const trendsData = Object.keys(sessions_per_day).map((day) => ({
    day,
    sessions: sessions_per_day[day],
  }));

  return (
    <div className="grid grid-cols-2 gap-4 w-full mt-4">
      {/* Risk Distribution Chart */}
      <Card toolTipText={toolTipText.client.riskDistribution}>
        <CardHeader>
          <CardTitle className="text-lg">Client Risk Distribution</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-64">
            {riskData.every((entry) => entry.value === 0) ? (
              <div className="flex items-center justify-center h-full text-gray-500">
                No data available
              </div>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={riskData}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={2}
                    dataKey="value"
                  >
                    {riskData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            )}
          </div>
        </CardContent>
      </Card>

      {/* Compatibility Score Rate */}
      <Card toolTipText={toolTipText.client.compatibilityScore}>
        <CardHeader>
          <CardTitle className="text-lg">Compatibility Score</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-64">
            {compatibilityScore === 0 ? (
              <div className="flex items-center justify-center h-full text-gray-500">
                No data available
              </div>
            ) : (
              <div className="relative w-full h-full">
                <ResponsiveContainer width="100%" height="100%">
                  <RadialBarChart
                    cx="50%"
                    cy="70%"
                    innerRadius="100%"
                    outerRadius="100%"
                    barSize={20}
                    data={[
                      {
                        name: "Score",
                        value: compatibilityScore,
                        fill: "#82ca9d",
                      },
                    ]}
                    startAngle={180}
                    endAngle={0}
                  >
                    <PolarAngleAxis
                      type="number"
                      domain={[0, 100]}
                      angleAxisId={0}
                      tick={false}
                    />
                    <RadialBar
                      minAngle={15}
                      background
                      clockWise
                      dataKey="value"
                    />
                  </RadialBarChart>
                </ResponsiveContainer>
                {/* Display Score in the Center */}
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-2xl font-bold text-gray-700">
                  {compatibilityScore}%
                </div>
              </div>
            )}
          </div>
        </CardContent>
      </Card>

      {/* Client Status Overview */}
      <Card toolTipText={toolTipText.client.clientStatusOverview}>
        <CardHeader>
          <CardTitle className="text-lg">Client Status Overview</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={statusData} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="status" type="category" />
                <Tooltip />
                <Bar dataKey="count" fill="#60A5FA" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>

      {/* Weekly Session Trends */}
      <Card toolTipText={toolTipText.client.weeklySessionTrends}>
        <CardHeader>
          <CardTitle className="text-lg">Weekly Session Trends</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={trendsData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="sessions"
                  stroke="#3B82F6"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ClientMetrics;
