import { createSlice } from "@reduxjs/toolkit";

const teamDashboardSlice = createSlice({
  name: "team_dashboard",
  initialState: {
    data: {
      invited_therapists: [],
      therapists: [],
    },
    loading: false,
  },
  reducers: {
    setTeamDashboard: (state, action) => {
      return { data: action.payload, loading: true };
    },
    addTherapist: (state, action) => {
      state.data.therapists.push(action.payload);
    },
    addInvitedTherapist: (state, action) => {
      state.data.invited_therapists.push(action.payload);
    },
  },
});

export const { setTeamDashboard, addTherapist, addInvitedTherapist } =
  teamDashboardSlice.actions;

export default teamDashboardSlice.reducer;
