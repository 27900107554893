import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ASSETS from "../../assets/Assets";
import "./Sidebar.css";
import {
  ChevronLeft,
  ChevronRight,
  Brain,
  Home,
  UserCircle,
  LayoutDashboard,
  BarChart,
  Settings,
  HelpCircle,
  LogOut,
  Users,
  ChartColumnBig,
} from "lucide-react";
import { replace } from "lodash";

export default function Sidebar({ userRole, logOut }) {
  return <NewTherapistSideBar userRole={userRole} logOut={logOut} />;
}

const NewTherapistSideBar = ({ userRole, logOut }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");
  const [isDrawerCollapsed, setIsDrawerCollapsed] = useState(false);

  const mainTabs = [
    {
      id: "overview",
      name: "Overview",
      icon: Home,
      route: "/overview",
    },
    // { id: "dashboard", name: "Dashboard", icon: Home, route: "/dashboard" },
    { id: "clients", name: "My Clients", icon: UserCircle, route: "/clients" },
    { id: "team", name: "Team", icon: Users, route: "/team" },
    { id: "analysis", name: "Analytics", icon: BarChart, route: "/analysis" },
  ];

  const settingsTabs = [
    {
      id: "settings",
      name: "Settings",
      icon: Settings,
      route: "/settings",
    },
    // {
    //   id: "help",
    //   name: "Help & Support",
    //   icon: HelpCircle,
    //   route: "https://therawin.health",
    // },
    { id: "logout", name: "Logout", icon: LogOut },
  ];

  useEffect(() => {
    setActiveTab(location.pathname.split("/")[1]);
  }, [location]);

  const handleLogout = () => {
    //window.location.href = "/";
    logOut();
  };

  return (
    <div
      className={`${
        isDrawerCollapsed ? "w-25" : "w-64"
      } bg-white border-r border-slate-200 flex flex-col relative transition-all duration-300 z-index-high`}
    >
      {/* Toggle Button */}
      <button
        onClick={() => setIsDrawerCollapsed(!isDrawerCollapsed)}
        className="absolute w-8 h-8 flex justify-center items-center -right-3 top-8 bg-white border border-slate-200 rounded-full p-1 shadow-sm hover:bg-slate-50"
      >
        {isDrawerCollapsed ? (
          <ChevronRight className="h-4 w-4 text-slate-600" />
        ) : (
          <ChevronLeft className="h-4 w-4 text-slate-600" />
        )}
      </button>

      {/* Logo Area */}
      <div className="h-16 border-b border-slate-200 flex items-center px-6">
        {isDrawerCollapsed ? (
          <Brain className="h-8 w-8 text-blue-600" />
        ) : (
          <h1 className="text-xl font-bold text-blue-600">TheraWin</h1>
        )}
      </div>

      {/* Main Navigation */}
      <nav className="p-4 flex-grow">
        {mainTabs
          .filter(
            (tab) =>
              userRole === "SUPERVISOR" ||
              userRole === "ADMIN" ||
              !["team"].includes(tab.id)
          )
          .map((tab) => {
            const Icon = tab.icon;
            return (
              <Link to={tab.route} style={{ textDecoration: "none" }}>
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`w-full flex items-center space-x-3 px-4 py-3 rounded-lg mb-1 text-left
            ${
              activeTab === tab.id
                ? "bg-blue-50 text-blue-600"
                : "text-slate-600 hover:bg-slate-50"
            }`}
                >
                  <Icon className="h-5 w-5 flex-shrink-0" />
                  {!isDrawerCollapsed && (
                    <span className="font-medium">{tab.name}</span>
                  )}
                </button>
              </Link>
            );
          })}
      </nav>

      {/* Settings Navigation */}
      <div className="p-4 border-t border-slate-200">
        {settingsTabs.map((tab) => {
          const Icon = tab.icon;
          return (
            <Link
              to={tab.route}
              style={{ textDecoration: "none" }}
              target={tab.id === "help" ? "_blank" : "_self"}
            >
              <button
                key={tab.id}
                onClick={
                  tab.id === "logout"
                    ? handleLogout
                    : tab.id === "help"
                    ? () => {}
                    : () => setActiveTab(tab.id)
                }
                className={`w-full flex items-center space-x-3 px-4 py-3 rounded-lg mb-1 text-left
            ${
              activeTab === tab.id
                ? "bg-blue-50 text-blue-600"
                : "text-slate-600 hover:bg-slate-50"
            }`}
              >
                <Icon className="h-5 w-5 flex-shrink-0" />
                {!isDrawerCollapsed && (
                  <span className="font-medium">{tab.name}</span>
                )}
              </button>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
