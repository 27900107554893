import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const startFirefilesSession = async (meeting_link, therapy_id) => {
  const baseURL = config.API_URL;
  const path = "/firefiles/start_firefiles_session";

  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.post(
    baseURL + path,
    {
      meeting_link,
      therapy_id,
    },
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
  return res;
};
