import React, { useEffect, useState } from "react";
import ASSETS, { loginPage } from "../../../assets/Assets";
import { useSearchParams } from "react-router-dom";
import "../AuthPage.css";
import "./TherapistAuthPage.css";
import {
  Authenticator,
  Button,
  Heading,
  View,
  useAuthenticator,
  useTheme,
  Flex,
  Text,
  Image,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "../AuthPage.css";
import { checkInvite } from "../../../api/invite";

const ContactUsForRegistration = () => {
  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      width="400px"
      // height="100vh"
    >
      <Heading level={3} textAlign="center" color="brand.primary">
        Interested in Joining Us?
      </Heading>
      <Text textAlign="center" fontSize="medium" marginTop="1rem">
        Our platform is invite-only to ensure the best experience for our
        professionals. To request access, please reach out to us at:
      </Text>
      <Text
        textAlign="center"
        fontWeight="bold"
        color="blue"
        marginTop="0.5rem"
      >
        <a href="mailto:contact@therawin.health">contact@therawin.health</a>
      </Text>
      <Button variation="primary" width="100%" marginTop="1.5rem">
        Go Back
      </Button>
    </Flex>
  );
};

function decodeBase64(base64String) {
  try {
    // Decode Base64 string
    const decodedString = atob(base64String);

    // Attempt to parse JSON
    const jsonData = JSON.parse(decodedString);

    //console.log("Valid JSON:", jsonData);
    return jsonData; // Return the parsed JSON object
  } catch (error) {
    console.warn("Invalid data.");
    return null; // Do nothing if it's not valid JSON
  }
}

function TherapistAuthPage() {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("THERAPIST");
  const [searchParams] = useSearchParams();
  const invite = searchParams.get("invite");

  const { tokens } = useTheme();
  // Access Authenticator's state management
  const { route, toSignUp, toSignIn, toResetPassword } = useAuthenticator(
    (context) => [context.user, context.hasValidationErrors, context.isPending]
  );

  const formFields = {
    signIn: {
      username: {
        label: "",
        placeholder: "Email",
      },
      password: {
        label: "",
        placeholder: "Password",
      },
    },
    signUp: {
      "custom:user_type": {
        order: 1,
        readOnly: true,
        // style: {
        //   display: "none",
        // },
      },
      email: {
        order: 2,
        label: "",
        readOnly: true,
        placeholder: "Email address",
      },
      name: {
        order: 3,
        label: "",
        placeholder: "Full name",
        isRequired: true,
      },
      password: {
        order: 4,
        label: "",
        placeholder: "Password",
      },
      confirm_password: {
        order: 5,
        label: "",
        placeholder: "Please confirm your password",
      },
      "custom:user_status": {
        // This field is kind of redundant not needed,
        // Maybe remove at later stage
        order: 6,
        value: "ACTIVE",
        readOnly: true,
        style: {
          display: "none",
        },
      },
      phone_number: {
        // dialCode: "+1",
        order: 7,
        value: "0000000000",
        style: {
          display: "none",
        },
      },
    },
  };

  const components = {
    SignIn: {
      Header() {
        return (
          <div className="flex flex-col justify-center items-center gap-10">
            <Image
              src={ASSETS.logoNewSvg}
              className="!w-[15rem] aspect-auto object-contain"
            />
            <Heading
              padding={`${tokens.space.xs} 0 0 ${tokens.space.xl}`}
              level={3}
              textAlign="left"
            >
              Login
            </Heading>
          </div>
        );
      },
    },
    SignUp: {
      Header() {
        return (
          <Heading
            padding={`${tokens.space.xs} 0 0 ${tokens.space.xl}`}
            level={3}
            textAlign="left"
            textTransform="capitalize"
          >
            {/* Change the header according to therapist and supervisor */}
            Register as {role.toLowerCase()}
          </Heading>
        );
      },
      Footer() {
        return (
          <View textAlign="center">
            Already have an account?
            <Button
              fontWeight="normal"
              onClick={
                // enable the contact us component
                toSignIn
              }
              variation="link"
              color="red"
            >
              Login
            </Button>
          </View>
        );
      },
    },
  };

  useEffect(() => {
    if (route === "signIn" && invite) {
      //decode invite
      const decodedJSON = decodeBase64(invite);

      // Check if decoded string is JSON string or not
      if (decodedJSON) {
        const email = decodedJSON["email"];

        if (email) {
          //call the api to get role
          checkInvite(email)
            .then((res) => {
              const user_role = res["role"];
              setRole(user_role);
              setEmail(email);

              //if api resonse is correct show sign up page with role
              toSignUp();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  }, [route, invite]);

  useEffect(() => {
    if (role && email) {
      let input = document.querySelector('input[name="custom:user_type"]');
      if (input) {
        input.setAttribute("value", role);
      }

      //Setting Organisation Name
      input = document.querySelector('input[name="email"]');
      if (input) {
        input.setAttribute("value", email);
      }
    }
  }, [role, email]);

  return (
    <div className="auth-page-wrapper">
      <div className="auth-page_column auth-page_left">
        <div className="login-form-wrapper">
          {/* <img className="login-form-logo" src={ASSETS.logoNewSvg} alt="logo" /> */}
          <div className="scroll-thin" style={{ overflowY: "overlay" }}>
            <Authenticator
              className="auth-form therapist-auth-form"
              signUpAttributes={["name", "email"]}
              loginMechanisms={["email"]}
              formFields={formFields}
              components={components}
            />
            {/* <ContactUsForRegistration /> */}
          </div>
        </div>
      </div>
      <div className="auth-page_column auth-page_right">
        <div className="auth-page-img-wrapper">
          <img className="auth-page-img" src={loginPage} alt="login" />
        </div>
      </div>
    </div>
  );
}

export default TherapistAuthPage;
