import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/card";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Brain,
  Heart,
  MessageCircle,
  Zap,
  ArrowRight,
  Loader2,
} from "lucide-react";

import { getRealtimeSessionInfoById } from "../../api/realtime";

// Custom tooltip component
const CustomTooltip = ({ active, payload, label, wrapperStyle }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200 max-w-md">
        <p className="font-medium text-gray-900">Time: {data.time}</p>
        <p className="text-gray-600 mt-1">Score: {data.score}</p>
        <p className="text-gray-600 mt-2 italic">"{data.sentence}"</p>
        <p className="text-gray-600 mt-1">{data.areas}</p>
      </div>
    );
  }
  return null;
};

// Function to render score charts
const renderScoreChart = (data, title, color = "#3B82F6") => (
  <Card>
    <CardHeader>
      <CardTitle className="text-lg">{title} Score Over Time</CardTitle>
    </CardHeader>
    <CardContent>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data} style={{ zIndex: 1 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis domain={[0, 100]} />
            <Tooltip
              content={<CustomTooltip />}
              wrapperStyle={{ marginTop: 20 }}
            />
            <Line
              type="monotone"
              dataKey="score"
              stroke={color}
              strokeWidth={2}
              dot={{ r: 4 }}
              activeDot={{ r: 6 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </CardContent>
  </Card>
);

function formatKeyName(key) {
  // スネークケースやキャメルケースをスペース区切りに変換し、1文字目を大文字
  // 例: "client_goals_and_concerns" => "Client Goals And Concerns"
  //     "therapistInterventions" => "Therapist Interventions"

  // スネークやキャメルに共通対応するため、まず単語の境界でsplit
  // 1) スネーク => replace('_', ' ')
  // 2) カメル => "clientGoalsAndConcerns" => "client Goals And Concerns"
  const withSpaces = key.replace(/_/g, " ").replace(/([a-z])([A-Z])/g, "$1 $2"); // aB => a B

  // 各単語の先頭を大文字化
  return withSpaces
    .split(" ")
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(" ");
}

function renderNestedStructure(data, level = 0) {
  if (data == null) return null;

  if (typeof data === "string" || typeof data === "number") {
    return <p className="ml-4 text-gray-700 my-0">{data}</p>;
  } else if (Array.isArray(data)) {
    return data.map((item, idx) => (
      <div key={idx} className="ml-4">
        {renderNestedStructure(item, level + 1)}
      </div>
    ));
  } else if (typeof data === "object") {
    return Object.entries(data).map(([k, v]) => {
      const formattedKey = formatKeyName(k);
      return (
        <div key={k} className="ml-4 mb-2">
          <span className="font-semibold text-gray-700">{formattedKey}:</span>
          {renderNestedStructure(v)}
        </div>
      );
    });
  }
  return null;
}

const SessionView = ({ therapistId, sessionId, sessionType }) => {
  console.log("sessionId", sessionId);
  console.log("sessionType", sessionType);
  // Sample data for the score charts
  // const sampleScores = {
  //     engagement: [
  //         { time: '0:00', score: 75, sentence: "How are you doing today?", areas: "Could use more open-ended questions" },
  //         { time: '0:30', score: 82, sentence: "Tell me more about that.", areas: "Good use of active listening" },
  //         { time: '1:00', score: 78, sentence: "I understand that must be difficult.", areas: "Consider deeper exploration" },
  //         { time: '1:30', score: 85, sentence: "What strategies have you tried?", areas: "Excellent probing question" },
  //         { time: '2:00', score: 80, sentence: "It sounds like this has been overwhelming.", areas: "Good validation" }
  //     ],
  //     empathy: [
  //         { time: '0:00', score: 85, sentence: "How are you doing today?", areas: "Good initial rapport" },
  //         { time: '0:30', score: 88, sentence: "Tell me more about that.", areas: "Strong emotional attunement" },
  //         { time: '1:00', score: 90, sentence: "I understand that must be difficult.", areas: "Excellent validation" },
  //         { time: '1:30', score: 92, sentence: "What strategies have you tried?", areas: "Shows genuine concern" },
  //         { time: '2:00', score: 87, sentence: "It sounds like this has been overwhelming.", areas: "Good emotional reflection" }
  //     ],
  //     communication: [
  //         { time: '0:00', score: 70, sentence: "How are you doing today?", areas: "Could be more specific" },
  //         { time: '0:30', score: 75, sentence: "Tell me more about that.", areas: "Clear and inviting" },
  //         { time: '1:00', score: 80, sentence: "I understand that must be difficult.", areas: "Well-structured response" },
  //         { time: '1:30', score: 82, sentence: "What strategies have you tried?", areas: "Good clarity" },
  //         { time: '2:00', score: 78, sentence: "It sounds like this has been overwhelming.", areas: "Clear reflection" }
  //     ],
  //     intervention: [
  //         { time: '0:00', score: 65, sentence: "How are you doing today?", areas: "Could be more targeted" },
  //         { time: '0:30', score: 70, sentence: "Tell me more about that.", areas: "Good follow-up" },
  //         { time: '1:00', score: 75, sentence: "I understand that must be difficult.", areas: "Effective intervention" },
  //         { time: '1:30', score: 78, sentence: "What strategies have you tried?", areas: "Strong therapeutic direction" },
  //         { time: '2:00', score: 72, sentence: "It sounds like this has been overwhelming.", areas: "Good support" }
  //     ]
  // };

  const [sessionDetail, setSessionDetail] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSessionDetail = async () => {
      try {
        setLoading(true);
        const resp = await getRealtimeSessionInfoById(
          therapistId,
          sessionId,
          sessionType
        );
        // { data: { session_id, start_datetime, end_datetime, full_report, ... } }
        console.log("resp", resp);
        if (!resp || !resp.data) {
          setSessionDetail(null);
          return;
        }
        const sessionJson = JSON.parse(resp.data.report);

        setSessionDetail({
          ...resp.data, // session_id, start_datetime, etc.
          full_report: sessionJson,
        });
      } catch (e) {
        console.error("Failed to fetch session info", e);
      } finally {
        setLoading(false);
      }
    };
    if (therapistId && sessionId) {
      fetchSessionDetail();
    }
  }, [therapistId, sessionId]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin  text-blue-500" />
      </div>
    );
  }
  if (!sessionDetail) {
    return <div>No session data found.</div>;
  }

  // sessionDetail.full_report が今回取りたいデータ
  const report = sessionDetail.full_report || {};
  // たとえば "scores" は sentenceごとの配列
  const scoresArray = report.scores || [];

  // それぞれのスコアを時間系列に構築
  // "time"は便宜上 "Sentence i" としているが、start, endなどを見てもOK
  const empathyData = [];
  const engagementData = [];
  const communicationData = [];
  const interventionData = [];

  scoresArray.forEach((item, index) => {
    const words = item.sentence?.trim().split(/\s+/) || [];
    if (words.length < 10) {
      return;
    }
    const timeLabel = `${index + 1}`;
    // empathy
    const eShort = item?.scores?.empathy_score?.area_of_improvement_short;
    const eFullArr = item?.scores?.empathy_score?.areas_of_improvement;
    const empathyAreas = Array.isArray(eFullArr) && eFullArr.length > 0
      ? eFullArr[0]
      : eShort
        ? eShort
        : "";

    const empVal = item?.scores?.empathy_score?.score || 0;
    empathyData.push({
      time: timeLabel,
      score: empVal,
      sentence: item.sentence,
      areas: empathyAreas,
    });
    // engagement
    const gShort = item?.scores?.engagement_score?.area_of_improvement_short;
    const gFullArr = item?.scores?.engagement_score?.areas_of_improvement;
    const engagementAreas = Array.isArray(gFullArr) && gFullArr.length > 0
      ? gFullArr[0]
      : gShort
        ? gShort
        : "";

    const engVal = item?.scores?.engagement_score?.score || 0;
    engagementData.push({
      time: timeLabel,
      score: engVal,
      sentence: item.sentence,
      areas: engagementAreas,
    });
    // communication -> language_and_communication_score
    const cShort =
      item?.scores?.language_and_communication_score?.area_of_improvement_short;
    const cFullArr =
      item?.scores?.language_and_communication_score?.areas_of_improvement;
    const communicationAreas = Array.isArray(cFullArr) && cFullArr.length > 0
      ? cFullArr[0]
      : cShort
        ? cShort
        : "";

    const commVal = item?.scores?.language_and_communication_score?.score || 0;
    communicationData.push({
      time: timeLabel,
      score: commVal,
      sentence: item.sentence,
      areas: communicationAreas,
    });
    // intervention
    const iShort = item?.scores?.intervention_score?.area_of_improvement_short;
    const iFullArr = item?.scores?.intervention_score?.areas_of_improvement;
    const interventionAreas = Array.isArray(iFullArr) && iFullArr.length > 0
      ? iFullArr[0]
      : iShort
        ? iShort
        : "";

    const intVal = item?.scores?.intervention_score?.score || 0;
    interventionData.push({
      time: timeLabel,
      score: intVal,
      sentence: item.sentence,
      areas: interventionAreas,
    });
  });

  // session info
  let dateLabel = "N/A";
  let startTime = "";
  let durationLabel = "--";

  if (sessionType === "ZOOM") {
    if (sessionDetail.start_time) {
      dateLabel = sessionDetail.start_time.substring(0, 10);
      startTime = sessionDetail.start_time.substring(11, 16);
    }

    if (sessionDetail.duration != null) {
      const diffMins = Math.round(sessionDetail.duration / 60);
      durationLabel = diffMins > 0 ? `${diffMins} mins` : "0 mins";
    }
  } else {
    if (sessionDetail.start_datetime) {
      dateLabel = sessionDetail.start_datetime.substring(0, 10);
      startTime = sessionDetail.start_datetime.substring(11, 16);
    }

    if (sessionDetail.start_datetime && sessionDetail.end_datetime) {
      const start = new Date(sessionDetail.start_datetime);
      const end = new Date(sessionDetail.end_datetime);
      const diffMs = end - start;
      const diffMins = Math.round(diffMs / 60000);
      if (diffMins > 0) {
        durationLabel = `${diffMins} mins`;
      }
    }
  }
  // Topics Covered
  // { "topics_covered": [ { topic, details }... ] }
  const topicsCovered = report.topics_covered || [];

  // Topic Suggestions
  // { "topic_suggestions": [ { topic, suggested_timing }... ] }
  const topicSuggestions = report.topic_suggestions || [];

  // Questions Analysis:
  //  open_and_closed_questions: { open_questions: [...], closed_questions: [...] }
  const openAndClosed = report.open_and_closed_questions || {};
  const openQ = openAndClosed.open_questions || [];
  const closedQ = openAndClosed.closed_questions || [];

  // openQ, closedQの先頭を表示させる or 全部表示？
  // もとの要件: 配列の長さを表示, 先頭要素を1つ表示
  const openQuestionsCount = openQ.length;
  const openQuestionsFirst = openQ[0] || "";
  const closedQuestionsCount = closedQ.length;
  const closedQuestionsFirst = closedQ[0] || "";

  return (
    <div className="space-y-6">
      {/* Session Info Header */}
      <div className="flex justify-between items-end">
        <div>
          <h2 className="text-2xl font-bold text-gray-900">Session Analysis</h2>
          <p className="text-gray-600">
            {dateLabel} - {startTime}
          </p>
        </div>
        <div className="flex gap-4">
          <div className="text-right">
            <p className="text-sm font-medium text-gray-600 my-2">Duration</p>
            <p className="text-lg font-semibold text-gray-900  my-2">
              {durationLabel}
            </p>
          </div>
          <div className="text-right">
            <p className="text-sm font-medium text-gray-600 my-2">Topics</p>
            <div className="flex flex-wrap gap-2 mt-1 justify-end">
              {topicsCovered.map((t, i) => (
                <span
                  key={i}
                  className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-xs"
                >
                  {t.topic}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Score Charts */}
      {/* <div className="grid grid-cols-2 gap-6">
                {renderScoreChart(sampleScores.empathy, "Empathy", "#3B82F6")}
                {renderScoreChart(sampleScores.engagement, "Engagement", "#60A5FA")}
                {renderScoreChart(sampleScores.communication, "Communication", "#93C5FD")}
                {renderScoreChart(sampleScores.intervention, "Intervention", "#BFDBFE")}
            </div> */}

      <div className="grid grid-cols-2 gap-6">
        {renderScoreChart(empathyData, "Empathy", "#1E40AF")}
        {renderScoreChart(engagementData, "Engagement", "#1D4ED8")}
        {renderScoreChart(communicationData, "Communication", "#2563eb")}
        {renderScoreChart(interventionData, "Intervention", "#3B82F6")}
      </div>

      {/* Analysis Boxes */}
      <div className="grid grid-cols-2 gap-6">
        {/* Follow-up Questions */}
        <Card>
          <CardHeader>
            <CardTitle>Follow-up Questions</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-3">
              {(report.follow_up_questions || []).map((fq, i) => (
                <div key={i} className="mb-4">
                  <span className="font-semibold text-gray-700 mb-1">{fq.topic}</span>
                  {fq.questions.map((q, idx) => (
                    <div key={idx} className="flex items-start gap-2">
                      <ArrowRight className="h-5 w-5 text-blue-500 mt-0.5" />
                      <p className="text-gray-700 my-0">{q}</p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        {/* Topic Suggestions */}
        <Card>
          <CardHeader>
            <CardTitle>Topic Suggestions</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-3">
              {topicSuggestions.map((ts, i) => (
                <div key={i} className="mb-4">
                  <span className="font-semibold text-gray-700 mb-1">{ts.topic}</span>
                  <div className="flex items-start gap-2">
                    <ArrowRight className="h-5 w-5 text-blue-500 mt-0.5" />
                    <p className="text-gray-700 my-0">
                      {ts.suggested_timing}
                    </p>
                  </div>
                </div>
              ))}

            </div>
          </CardContent>
        </Card>

        {/* Questions Analysis */}
        <Card>
          <CardHeader>
            <CardTitle>Questions Analysis</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {/* Open-ended */}
              <div>
                <div className="flex justify-between mb-2">
                  <span className="font-semibold text-gray-700  my-1">
                    Open-ended Questions
                  </span>
                  <span className="text-blue-600 my-1">
                    {openQuestionsCount} questions
                  </span>
                </div>
                {openQ.map((question) => {
                  return (
                    <div className="bg-blue-50 p-3 rounded-lg mb-4">
                      <p className="text-sm text-gray-600 my-0">{question}</p>
                    </div>
                  );
                })}
                {/* {openQuestionsFirst && (
                  <div className="bg-blue-50 p-3 rounded-lg">
                    <p className="text-sm text-gray-600 my-0">
                      "{openQuestionsFirst}"
                    </p>
                  </div>
                )} */}
              </div>
              {/* Closed-ended */}
              <div>
                <div className="flex justify-between mb-2">
                  <span className="font-semibold text-gray-700  my-1">
                    Closed-ended Questions
                  </span>
                  <span className="text-blue-600 my-1">
                    {closedQuestionsCount} questions
                  </span>
                </div>
                {closedQ.map((question) => {
                  return (
                    <div className="bg-blue-50 p-3 rounded-lg mb-4">
                      <p className="text-sm text-gray-600 my-0">{question}</p>
                    </div>
                  );
                })}
                {/* {closedQuestionsFirst && (
                  <div className="bg-blue-50 p-3 rounded-lg">
                    <p className="text-sm text-gray-600  my-0">
                      "{closedQuestionsFirst}"
                    </p>
                  </div>
                )} */}
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Topics Covered */}
        <Card>
          <CardHeader>
            <CardTitle>Topics Covered</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-3">
              {/* <div>
                                <h4 className="font-medium text-gray-700 mb-2">Primary Topics</h4>
                                <div className="flex flex-wrap gap-2">
                                    <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                                        Work Stress
                                    </span>
                                    <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                                        Anxiety
                                    </span>
                                    <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
                                        Time Management
                                    </span>
                                </div>
                            </div>
                            <div>
                                <h4 className="font-medium text-gray-700 mb-2">Related Topics</h4>
                                <div className="flex flex-wrap gap-2">
                                    <span className="px-3 py-1 bg-gray-100 text-gray-800 rounded-full text-sm">
                                        Sleep
                                    </span>
                                    <span className="px-3 py-1 bg-gray-100 text-gray-800 rounded-full text-sm">
                                        Self-care
                                    </span>
                                </div>
                            </div> */}

              {topicsCovered.map((tc, i) => (
                <div key={i}>
                  {/* <h4 className="font-medium text-gray-700 mb-1">{tc.topic}</h4> */}
                  <span
                    key={i}
                    className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-xs b"
                  >
                    {tc.topic}
                  </span>
                  <p className="text-sm text-gray-600 my-0 mb-2">
                    {tc.details}
                  </p>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Session Summary */}
      <Card>
        <CardHeader>
          <CardTitle>Session Summary</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {/* <div>
                            <h4 className="font-medium text-gray-700 mb-2">Emotional Context</h4>
                            <p className="text-gray-600">
                                Client expressed significant anxiety regarding work-life balance and career pressures.
                                Notable emotional fluctuations when discussing workplace challenges.
                            </p>
                        </div>
                        <div>
                            <h4 className="font-medium text-gray-700 mb-2">Key Insights</h4>
                            <p className="text-gray-600">
                                Strong self-awareness but struggling with practical coping mechanisms.
                                Shows readiness to implement stress management strategies.
                            </p>
                        </div>
                        <div>
                            <h4 className="font-medium text-gray-700 mb-2">Progress Notes</h4>
                            <p className="text-gray-600">
                                Improvement in recognizing anxiety triggers. Successfully implemented
                                breathing exercises from previous session.
                            </p>
                        </div> */}
            {renderNestedStructure(report.summary)}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SessionView;
