import React, { useEffect, useRef, useState } from "react";
import {
  createNewNotes,
  fetchNotesByTherapyId,
  fetchProgressNoteBySessionId,
} from "../../api/notes";
import { Card, CardContent } from "../../components/card";
import { FileText, Plus } from "lucide-react";
import { Button } from "../../components/button";
import ProgressNoteModal from "./ProgressNoteModal";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import { mysqlDatetimeToJavascriptDatetime } from "../../utils/helpers";

function NotesTabPanel({ notes, therapy_id }) {
  const [currentNote, setCurrentNote] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleNoteClick = async (note) => {
    try {
      const detailResponse = await fetchProgressNoteBySessionId(
        note.session_id,
        therapy_id
      );
      console.log("detailResponse", detailResponse);
      setCurrentNote({
        ...detailResponse,
        created_at: note.start_time,
      });
      setModalOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNewNoteClick = async () => {
    const newNote = await createNewNotes(
      "therapy_id_placeholder",
      "new_note_id",
      "",
      ""
    );
    setCurrentNote(newNote);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setCurrentNote(null);
  };

  return (
    <>
      {/* Progress Note Detail Dialog */}
      {currentNote && (
        <ProgressNoteModal
          isOpen={modalOpen}
          onClose={handleClose}
          sessionDate={new Date().toDateString()}
          editable={false}
          data={currentNote}
        />
      )}

      <div className="space-y-4">
        {notes.map((note, i) => (
          <Card
            key={i}
            className="cursor-pointer hover:shadow-md transition-shadow"
            onClick={() => {
              handleNoteClick(note);
            }}
          >
            <CardContent className="pt-6">
              <div className="flex items-center gap-2 mb-2">
                <FileText className="h-4 w-4 text-blue-500" />
                <span className="font-medium">
                  {format(
                    parseISO(
                      mysqlDatetimeToJavascriptDatetime(note.start_time) // Ensure this function exists
                    ),
                    "do MMM y"
                  )}
                </span>
              </div>
              <p className="text-gray-700 line-clamp-3">{note.summary}</p>
            </CardContent>
          </Card>
        ))}
        {notes.length <= 0 && "No Notes Available"}
      </div>
    </>
  );
}

export default NotesTabPanel;
