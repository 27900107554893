import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import TeamList from "./TeamList";
import TeamMember from "./TeamMember";

const Team = () => {
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const data = useSelector((store) => store.teamDashboard.data);

  const invited_therapists = data["invited_therapists"];
  const therapists = invited_therapists.concat(data["therapists"]);

  const handleTherapistSelect = (therapist) => {
    if (therapist.therapist_id) {
      setSelectedTherapist(therapist);
      searchParams.set("therapist", therapist.therapist_id);
      setSearchParams(searchParams);
    }
  };

  const handleOnClickBack = () => {
    setSelectedTherapist(null);
    searchParams.set("therapist", "");
    setSearchParams(searchParams);
  };

  return selectedTherapist ? (
    <TeamMember
      selectedTherapist={selectedTherapist}
      handleTherapistSelect={handleTherapistSelect}
      handleOnClickBack={handleOnClickBack}
    />
  ) : (
    <TeamList
      therapists={therapists}
      handleTherapistSelect={handleTherapistSelect}
    />
  );
};

export default Team;
