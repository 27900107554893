import ASSETS from "../../../../assets/Assets";
import "./clientInfoTile.css";
import { useLocation, Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import { useEffect } from "react";
import { useState } from "react";
import { readUserFilesURL } from "../../../../api/s3";
import { format, parseISO } from "date-fns";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../utils/helpers";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/card";

const ClientInfoTile = function ({
  last_session_date,
  next_session_date,
  risk_level,
  status,
  therapy_id,
  patient_id,
  name,
}) {
  const location = useLocation();

  return (
    <Card className="hover:shadow-lg transition-shadow">
      <CardHeader className="pb-2">
        <div className="flex justify-between items-start">
          <CardTitle className="text-lg font-semibold">{name}</CardTitle>
          {risk_level && (
            <span
              className={`px-3 py-1 rounded-full text-sm ${
                risk_level === "HIGH"
                  ? "bg-red-100 text-red-700"
                  : risk_level === "MEDIUM"
                  ? "bg-amber-100 text-amber-700 "
                  : "bg-green-100 text-green-700"
              }`}
              style={{
                textTransform: "capitalize",
              }}
            >
              {risk_level.toLowerCase()} Risk
            </span>
          )}
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Status</span>
            <span
              className="font-medium"
              style={{ textTransform: "capitalize" }}
            >
              {status && status.toLowerCase()}
            </span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Last Session</span>
            <span className="font-medium">
              {" "}
              {last_session_date &&
                format(
                  parseISO(
                    mysqlDatetimeToJavascriptDatetime(last_session_date)
                  ),
                  "do MMM y"
                )}
              {!last_session_date && "No sessions conducted"}
            </span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Next Session</span>
            <span className="font-medium">
              {next_session_date &&
                format(
                  parseISO(
                    mysqlDatetimeToJavascriptDatetime(next_session_date)
                  ),
                  "do MMM y"
                )}
              {!next_session_date && "No sessions scheduled"}
            </span>
          </div>
          <div className="pt-4">
            <Link
              to={
                location.pathname +
                "/client-chart?patient_id=" +
                patient_id +
                "&therapy_id=" +
                therapy_id
              }
            >
              <button className="w-full py-2 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 border-none">
                View Details
              </button>
            </Link>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ClientInfoTile;
