import "./Client.css";
import { Route, Routes } from "react-router-dom";
import ClientHome from "./components/clientsDetails/clientHome";
import LeadClieenList from "./components/leadClients/leadClieenList/leadClieenList";
import LeadChart from "./components/leadClients/leadChart/leadChart";
import ClientDetailsPage from "../ClientDetails/ClientDetails";

function ClientScreen() {
  return (
    <div className="client-screen-wrapper min-h-screen">
      <Routes>
        <Route path="/" element={<ClientHome />} />
        <Route path="/leads" element={<LeadClieenList />} />
        <Route path="/client-chart" element={<ClientDetailsPage />} />
        <Route path="/lead-chart" element={<LeadChart />} />
      </Routes>
    </div>
  );
}

export default ClientScreen;
