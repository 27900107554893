import { createSlice } from "@reduxjs/toolkit";

const therapistSlice = createSlice({
  name: "therapist",
  initialState: {
    therapist_id: "",
    name: "",
    email: "",
    profile_photo_bucket: "",
    profile_photo_key: "",
    verification_doc_bucket: "",
    verification_doc_key: "",
    gender: "",
    status: "",
    age: "",
    contact_number: "",
    zoom_user_id: "",
    updated_at: "",
    created_at: "",
    profile_url: "",
    manager_email: "",
    partner_emails: "",
    country: "",
    designation: "",
    policy: "",
    qualification: [],
    bio: "",
  },
  reducers: {
    setTherapist: (state, action) => {
      return action.payload;
    },
    updateTherapistAge: (state, action) => {
      state.age = action.payload;
      return state;
    },
    updateTherapistName: (state, action) => {
      state.name = action.payload;
      return state;
    },
    updateTherapistGender: (state, action) => {
      state.gender = action.payload;
      return state;
    },
    updateTherapistContactNumber: (state, action) => {
      state.contact_number = action.payload;
      return state;
    },
    updateTherapistProfileUrl: (state, action) => {
      state.profile_url = action.payload;
      return state;
    },
    updateTherapistManagerEmail: (state, action) => {
      state.manager_email = action.payload;
      return state;
    },
    updateTherapistPeerEmails: (state, action) => {
      state.partner_emails = action.payload;
      return state;
    },
    updateTherapistCountry: (state, action) => {
      state.country = action.payload;
      return state;
    },
    updateTherapistDesignation: (state, action) => {
      state.designation = action.payload;
      return state;
    },
    updateTherapistPolicy: (state, action) => {
      state.policy = action.payload;
      return state;
    },
    updateTherapistSettings: (state, action) => {
      state.qualification = action.payload.qualifications;
      state.bio = action.payload.bio;
      state.designation = action.payload.designation;
      return state;
    },
    logoutResetStore: (state) => {},
  },
});

export const {
  updateTherapistAge,
  updateTherapistName,
  updateTherapistGender,
  updateTherapistContactNumber,
  updateTherapistProfileUrl,
  updateTherapistManagerEmail,
  updateTherapistPeerEmails,
  updateTherapistCountry,
  updateTherapistDesignation,
  updateTherapistPolicy,
  setTherapist,
  updateTherapistSettings,
  logoutResetStore,
} = therapistSlice.actions;

export default therapistSlice.reducer;
