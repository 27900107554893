import React, { useState, useEffect, useRef } from "react";
import { Button } from "../../components/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/select";
import { Textarea } from "../../components/textarea";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/card";
import { Save, Check } from "lucide-react";
import { updateProgressNotesAPI } from "../../api/notes";
import { format, parseISO } from "date-fns";
import {
  capitalizeFirstLetter,
  mysqlDatetimeToJavascriptDatetime,
} from "../../utils/helpers";

const ProgressNoteModal = ({
  isOpen,
  onClose,
  sessionDate,
  editable,
  data,
  realTimeSessionId = null,
}) => {
  console.log(data);

  const [selectedFormat, setSelectedFormat] = useState("SOAP");
  const [sections, setSections] = useState(data["notes"]["SOAP"]);
  const [personalNotes, setPersonalNotes] = useState(data["notes"]["my_notes"]);
  const [isEdited, setIsEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveComplete, setSaveComplete] = useState(false);
  const dataRef = useRef(data["notes"]);

  useEffect(() => {
    setSections(data["notes"][selectedFormat]);
  }, [selectedFormat]);

  const handleSectionChange = (index, newContent) => {
    const newSections = [...sections];
    newSections[index] = { ...newSections[index], content: newContent };
    setSections(newSections);
    dataRef.current[selectedFormat] = newSections;
    setIsEdited(true);
  };

  const handleSave = async () => {
    setIsSaving(true);
    // console.log("Saving progress note:", {
    //   format: selectedFormat,
    //   sections,
    //   personalNotes,
    // });

    //Adding personal_notes
    dataRef.current["my_notes"] = personalNotes;

    await updateProgressNotesAPI(realTimeSessionId, dataRef.current);
    setIsSaving(false);
    setSaveComplete(true);
    setIsEdited(false);

    // Reset save complete state after 2 seconds
    setTimeout(() => {
      setSaveComplete(false);
    }, 2000);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl max-h-[90vh] overflow-hidden flex flex-col">
        <DialogHeader className="border-b pb-4 flex flex-row items-center justify-between">
          <div className="flex-1">
            <DialogTitle className="text-2xl">Progress Notes</DialogTitle>
            <p className="text-gray-500 mt-2 text-sm">
              This progress note has been automatically generated based on your
              session on{" "}
              {data.created_at
                ? format(
                    parseISO(
                      mysqlDatetimeToJavascriptDatetime(data.created_at) // Ensure this function exists
                    ),
                    "do MMM y"
                  )
                : format(new Date(), "do MMM y")}
              {editable &&
                ". You can edit any section to customize the content."}
            </p>
          </div>
          {editable && (
            <Button
              onClick={handleSave}
              disabled={!isEdited || isSaving}
              className={`ml-4 relative ${
                saveComplete ? "bg-green-600 hover:bg-green-700" : ""
              } transition-colors duration-200`}
            >
              <div className="flex items-center">
                {!isSaving && !saveComplete && (
                  <>
                    <Save className="h-4 w-4 mr-2" />
                    <span>Save Changes</span>
                  </>
                )}
                {isSaving && (
                  <div className="flex items-center">
                    <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent mr-2"></div>
                    <span>Saving...</span>
                  </div>
                )}
                {saveComplete && (
                  <>
                    <Check className="h-4 w-4 mr-2" />
                    <span>Saved!</span>
                  </>
                )}
              </div>
            </Button>
          )}
        </DialogHeader>

        <div className="flex-1 overflow-y-auto py-4">
          <div className="space-y-6">
            {/* Format Selector */}
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700">
                Report Format
              </label>
              <Select
                value={selectedFormat}
                onValueChange={(value) => setSelectedFormat(value)}
              >
                <SelectTrigger className="w-[200px]">
                  <SelectValue placeholder="Select format" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="SOAP">SOAP Format</SelectItem>
                  <SelectItem value="DAP">DAP Format</SelectItem>
                  <SelectItem value="BIRP">BIRP Format</SelectItem>
                  <SelectItem value="GIRP">GIRP Format</SelectItem>
                </SelectContent>
              </Select>
            </div>

            {/* Report Sections */}
            <div className="space-y-6">
              {sections.map((section, index) => (
                <Card key={index}>
                  <CardHeader>
                    <CardTitle className="text-lg text-blue-700">
                      {capitalizeFirstLetter(section.title)}
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <Textarea
                      value={section.content}
                      onChange={(e) =>
                        handleSectionChange(index, e.target.value)
                      }
                      className={`min-h-[100px] resize-none ${
                        !editable ? "bg-gray-50" : ""
                      }`}
                      readOnly={!editable}
                    />
                  </CardContent>
                </Card>
              ))}
            </div>

            {/* Personal Notes Section */}
            <Card className="mt-6">
              <CardHeader>
                <CardTitle className="text-lg text-blue-700">
                  My Notes
                </CardTitle>
              </CardHeader>
              <CardContent>
                <Textarea
                  placeholder={
                    editable
                      ? "Add your personal notes about the session here..."
                      : "No personal notes added"
                  }
                  value={
                    personalNotes === "N/A" ? "No notes added" : personalNotes
                  }
                  onChange={(e) => {
                    if (!editable) return;
                    setPersonalNotes(e.target.value);
                    setIsEdited(true);
                    setSaveComplete(false);
                  }}
                  className={`min-h-[150px] resize-none ${
                    !editable ? "bg-gray-50" : ""
                  }`}
                  readOnly={!editable}
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ProgressNoteModal;
