import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Provider, useSelector, useDispatch } from "react-redux";
import store from "../store/rootStore";

import TherapistDashboard from "../screens/dashboard/Dashboard";
import Sidebar from "../components/sidebar/Sidebar";
import Settings from "../screens/settings/Settings";
import TherapistAuthApp from "../components/auth/therapist/TherapistAuthApp";

import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";

import { therapists_aws } from "../utils/aws-exports";
import ClientChart from "../screens/dashboard/components/clientChart/clientChart";
import ClientScreen from "../screens/dashboard/Client";
import Unverified from "../screens/unverified/Unverified";
import Team from "../screens/team";
import Analysis from "../screens/analysis/Analysis";
import { Toaster } from "react-hot-toast";

import { getTherapistDashboardByEmailAPI } from "../api/therapist";
import { getTherapistClientsByName } from "../api/therapist";
import { getSupervisorOverview } from "../api/therapist";
import { fetchSessionsAPI } from "../api/sessions";
import { setSessions } from "../store/sessions/sessionsSlice";
import { setTherapistDashboard } from "../store/therapistDashboard/therapistDashboardSlice";
import {
  setClientDashboard,
  setClientDashboardLoading,
  setCompatibilityScore,
} from "../store/clientDashboard/clientDashboardSlice";

import { ThreeDots } from "react-loader-spinner";
import { useAuthenticator } from "@aws-amplify/ui-react";
import ManualUpload from "../screens/manualUpload/ManualUpload";
import Overview from "../screens/overview/Overview";
import {
  setOverviewDashboard,
  setOverviewDashboardLoading,
} from "../store/overviewDashboard/overviewDashboardSlice";
import {
  getCompatibilityScoreByClient,
  getSupervisorTeamMembers,
} from "../api/supervisor";
import { setTeamDashboard } from "../store/teamDashboard/teamDashboardSlice";
import SettingsPage from "../screens/setting-v2/Settings";

Amplify.configure(therapists_aws);

const theme = createTheme({
  palette: {
    primary: {
      main: "#5F6CE1",
    },
  },
});

function calculateAverageScore(data) {
  // Filter out scores that are 0
  const validScores = data
    .filter((entry) => entry.score > 0)
    .map((entry) => entry.score);

  // If there are no valid scores, return 0 to avoid division by zero
  if (validScores.length === 0) return 0;

  // Calculate the average
  const averageScore =
    validScores.reduce((sum, score) => sum + score, 0) / validScores.length;

  return averageScore;
}

const TherapistApp = ({ logOut }) => {
  // const { status } = useSelector((store) => store.therapist);
  // console.log(status);
  //const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { user } = useAuthenticator((context) => [
    context.user,
    context.user.attributes,
  ]);
  const [status, setStatus] = useState("");
  // if user_status is verified, then load this component
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    if (user && user["attributes"]) {
      setStatus(user["attributes"]["custom:user_status"]);
      if (
        user["attributes"]["custom:user_status"] === "VERIFIED" ||
        user["attributes"]["custom:user_status"] === "SKIPPED" ||
        user["attributes"]["custom:user_status"] === "ACTIVE" ||
        user["attributes"]["custom:user_status"] === "ACCEPTED"
      ) {
        const therapist_id = user.attributes["sub"];
        const email = user.attributes["email"];
        setUserRole(user["attributes"]["custom:user_type"]);
        dispatch(setClientDashboardLoading(true));
        dispatch(setOverviewDashboardLoading(true));

        Promise.all([
          fetchSessionsAPI(therapist_id)
            .then((res) => {
              dispatch(setSessions(res));
            })
            .catch((err) => {
              console.log(err);
            }),
          getTherapistDashboardByEmailAPI(email)
            .then((res) => {
              dispatch(setTherapistDashboard(res.data));
            })
            .catch((err) => {
              console.log(err);
            }),
          getTherapistClientsByName("", therapist_id)
            .then((res) => {
              dispatch(setClientDashboard(JSON.parse(res)));
              // Getting compatiblity score
              getCompatibilityScoreByClient(therapist_id)
                .then((res) => {
                  const data = res.data;
                  const average = calculateAverageScore(
                    data["compatibility_scores"]
                  );
                  dispatch(setCompatibilityScore(Math.round(average)));
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            }),
          getSupervisorOverview(therapist_id)
            .then((res) => {
              dispatch(setOverviewDashboard(res));
            })
            .catch((err) => {
              console.log(err);
            }),
          getSupervisorTeamMembers(therapist_id)
            .then((res) => {
              if (
                user["attributes"]["custom:user_type"] === "SUPERVISOR" ||
                user["attributes"]["custom:user_type"] === "ADMIN"
              ) {
                dispatch(setTeamDashboard(res.data));
              }
            })
            .catch((err) => {
              console.log(err);
            }),
        ]).then((res) => {
          //setLoading(false);
          dispatch(setClientDashboardLoading(false));
          dispatch(setOverviewDashboardLoading(false));
        });
      }
    }
  }, [user]);

  const validStatuses = [
    "ACCEPTED",
    "ACTIVE",
    "APPROVED",
    "VERIFIED",
    "SKIPPED",
  ];

  return (
    <>
      {validStatuses.includes(status) ? (
        <div className="app">
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Sidebar userRole={userRole} logOut={logOut} />
              <div className="main-content-wrapper flex-1">
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate to="/overview" replace />}
                  />
                  <Route path="/overview" element={<Overview />} />
                  {/* <Route path="/dashboard" element={<TherapistDashboard />} /> */}
                  <Route path="/clients/*" element={<ClientScreen />} />
                  {(userRole === "SUPERVISOR" || userRole === "ADMIN") && (
                    <Route path="/team" element={<Team />} />
                  )}
                  <Route path="/analysis" element={<Analysis />} />
                  {/* <Route path="/manual-upload/*" element={<ManualUpload />} /> */}
                  <Route path="/settings" element={<SettingsPage />} />
                  {/* Fallback Route */}
                  <Route
                    path="*"
                    element={<div>Seems like you are lost</div>}
                  />
                </Routes>
              </div>
              <Toaster position="bottom-right" reverseOrder={false} />
            </LocalizationProvider>
          </ThemeProvider>
        </div>
      ) : (
        <div className="ring-loader">
          <ThreeDots
            height="100"
            width="100"
            color="#5F6CE1"
            radius="50"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />
        </div>
      )}
    </>
  );
};

const MindlyTherapistApp = () => {
  return (
    <Authenticator.Provider>
      <Provider store={store}>
        <TherapistAuthApp App={TherapistApp} />
      </Provider>
    </Authenticator.Provider>
  );
};
export default MindlyTherapistApp;
