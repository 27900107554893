import React, { useState } from "react";
import { Button } from "../../components/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/dialog";
import {
  UserPlus,
  Mail,
  Loader2,
  X,
  CheckCircle,
  RefreshCw,
} from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import {
  addTherapist,
  addInvitedTherapist,
} from "../../store/teamDashboard/teamDashboardSlice";
import { addTherapistManually, addTherapistViaInvite } from "../../api/teams";
// import { toast } from "sonner";

//This is called in CustomDataGridToolBar
const AddMember = () => {
  const data = useSelector((store) => store.therapist);
  const role = data["role"];

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    role: "",
    designation: "",
    contact_number: "",
    email: "",
    bio: "",
    insurance: [],
    qualifications: [],
    availability: { startTime: "09:00", endTime: "17:00" }, // Updated format
  });

  const [newQualification, setNewQualification] = useState("");

  const insuranceOptions = [
    "Blue Cross Blue Shield",
    "Aetna",
    "UnitedHealthcare",
    "Cigna",
    "Medicare",
    "Medicaid",
  ];

  const [inviteLoading, setInviteLoading] = useState(false);
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteRole, setInviteRole] = useState("");
  const [status, setStatus] = useState({ type: "", message: "" });

  const handleInsuranceChange = (e) => {
    const value = e.target.value;
    if (value && !formData.insurance.includes(value)) {
      setFormData({ ...formData, insurance: [...formData.insurance, value] });
      e.target.value = "";
    }
  };

  const removeInsurance = (insurance) => {
    setFormData({
      ...formData,
      insurance: formData.insurance.filter((item) => item !== insurance),
    });
  };

  const handleAddQualification = () => {
    if (newQualification.trim() !== "") {
      setFormData((prev) => ({
        ...prev,
        qualifications: [...prev.qualifications, newQualification.trim()],
      }));
      setNewQualification(""); // Clear input after adding
    }
  };

  const handleRemoveQualification = (index) => {
    setFormData((prev) => ({
      ...prev,
      qualifications: prev.qualifications.filter((_, i) => i !== index),
    }));
  };

  // Add this function at the top with your other functions
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Modify the validateStep0 function to include email validation
  const validateStep0 = () => {
    return (
      formData.name.trim() !== "" &&
      formData.role !== "" &&
      formData.email.trim() !== "" &&
      isValidEmail(formData.email)
    );
  };

  const handleCloseAddTherapistDialog = () => {
    setFormData({
      name: "",
      role: "",
      designation: "",
      contact_number: "",
      email: "",
      bio: "",
      insurance: [],
      qualifications: [],
      availability: { startTime: "09:00", endTime: "17:00" },
    });
    setCurrentStep(0);
    setNewQualification("");
    setSubmitSuccess(false);
    setSubmitError(false);
    setIsSubmitting(false);
    setIsDialogOpen(false);
  };

  const handleCloseInviteDialog = () => {
    setIsInviteDialogOpen(false);
    setStatus({ type: "", message: "" });
    setInviteEmail("");
    setInviteRole("");
  };

  // Add this function to handle form submission
  const handleAddTherapist = async () => {
    // Replace this with your actual API call
    try {
      setIsSubmitting(true);
      setSubmitError(false);

      // Your API call here
      const response = await addTherapistManually(
        data["therapist_id"],
        data["org_id"],
        role,
        formData
      );

      // Handle success
      setSubmitSuccess(true);

      // Dispatch
      dispatch(addTherapist(response["therapist_data"][0]));
    } catch (error) {
      console.error("Error submitting therapist data:", error);
      setSubmitError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInviteTherapist = async () => {
    setInviteLoading(true);
    setStatus({ type: "", message: "" });

    try {
      // Your API call here
      await addTherapistViaInvite(
        data["org_id"],
        data["therapist_id"],
        inviteRole,
        inviteEmail
      );

      setStatus({
        type: "success",
        message:
          "Invitation sent successfully! The therapist will receive an email shortly.",
      });
      setInviteEmail(""); // Clear the email input after success
      setInviteRole("");

      //Dispatch
      dispatch(
        addInvitedTherapist({
          email: inviteEmail,
          role: inviteRole,
          status: "INVITED",
        })
      );
    } catch (error) {
      console.log(error);
      setStatus({
        type: "error",
        message: "Failed to send invitation. Please try again.",
      });
    } finally {
      setInviteLoading(false);
    }
  };

  return (
    <div className="flex items-center gap-4 mb-6 mt-2">
      {/* Add Therapist Dialog */}
      <Dialog
        open={isDialogOpen}
        onOpenChange={(open) => {
          if (!open) {
            handleCloseAddTherapistDialog();
          } else {
            setIsDialogOpen(true);
          }
        }}
      >
        <DialogTrigger asChild>
          <Button className="bg-blue-600 hover:bg-blue-700 text-white flex items-center gap-2">
            Add Therapist
          </Button>
        </DialogTrigger>
        {/*  */}
        <DialogContent
          className="sm:max-w-lg h-[500px] overflow-auto"
          onPointerDownOutside={(e) => {
            e.preventDefault();
          }}
        >
          <DialogHeader>
            <DialogTitle>Add New Therapist</DialogTitle>
            <DialogDescription>
              Fill in the details to manually add a new therapist to your
              practice.
            </DialogDescription>
            <div className="flex justify-between items-center mt-4 w-full">
              {["Basic Info", "Professional", "Schedule", "Review"].map(
                (step, index) => (
                  <div key={step} className="flex items-center">
                    <div
                      className={`w-8 h-8 rounded-full flex items-center justify-center ${
                        currentStep > index
                          ? "bg-blue-600 text-white"
                          : currentStep === index
                          ? "bg-blue-100 text-blue-600 border-2 border-blue-600"
                          : "bg-gray-100 text-gray-400"
                      }`}
                    >
                      {currentStep > index ? "✓" : index + 1}
                    </div>
                    {index < 3 && (
                      <div
                        className={`w-28 h-1 ${
                          currentStep > index ? "bg-blue-600" : "bg-gray-200"
                        }`}
                      />
                    )}
                  </div>
                )
              )}
            </div>
          </DialogHeader>
          <div className="mt-4">
            <form className="">
              {currentStep === 0 && (
                <div className="space-y-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <label className="text-sm font-medium">Name *</label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border rounded-md border-gray-300"
                        placeholder="Dr. Jane Smith"
                        value={formData.name}
                        onChange={(e) =>
                          setFormData({ ...formData, name: e.target.value })
                        }
                      />
                    </div>
                    <div className="space-y-2">
                      <label className="text-sm font-medium">Role *</label>
                      <select
                        className="w-full px-3 py-2 border rounded-md border-gray-300"
                        value={formData.role}
                        onChange={(e) =>
                          setFormData({ ...formData, role: e.target.value })
                        }
                      >
                        <option value="" disabled>
                          Select your role
                        </option>
                        {role === "ADMIN" ? (
                          <option value="ADMIN">Admin</option>
                        ) : (
                          ""
                        )}
                        {role === "ADMIN" ? (
                          <option value="SUPERVISOR">Supervisor</option>
                        ) : (
                          ""
                        )}
                        <option value="THERAPIST">Therapist</option>
                      </select>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <label className="text-sm font-medium">
                        Mobile Number
                      </label>
                      <input
                        type="tel"
                        className="w-full px-3 py-2 border rounded-md"
                        placeholder="+1 (555) 000-0000"
                        value={formData.contact_number}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            contact_number: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="space-y-2">
                      <label className="text-sm font-medium">Email *</label>
                      <input
                        type="email"
                        className="w-full px-3 py-2 border rounded-md border-gray-300"
                        placeholder="jane.smith@example.com"
                        value={formData.email}
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 1 && (
                <div className="space-y-4">
                  <div className="space-y-2">
                    <label className="text-sm font-medium">Designation</label>
                    <input
                      type="text"
                      className="w-full px-3 py-2 border rounded-md"
                      placeholder="Clinical Psychologist"
                      value={formData.designation}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          designation: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="text-sm font-medium">
                      Professional Bio
                    </label>
                    <textarea
                      className="w-full px-3 py-2 border rounded-md h-24"
                      placeholder="Enter professional background and expertise..."
                      value={formData.bio}
                      onChange={(e) =>
                        setFormData({ ...formData, bio: e.target.value })
                      }
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="text-sm font-medium">
                      Insurance Acceptance
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        list="insuranceOptions"
                        className="w-full px-3 py-2 border rounded-md"
                        placeholder="Type to search insurance..."
                        onChange={handleInsuranceChange}
                      />
                      <datalist id="insuranceOptions">
                        {insuranceOptions.map((option, index) => (
                          <option key={index} value={option} />
                        ))}
                      </datalist>
                      <div className="flex flex-wrap gap-2 mt-2">
                        {formData.insurance.map((insurance, index) => (
                          <div
                            key={index}
                            className="flex items-center gap-1 bg-blue-50 text-blue-700 px-2 py-1 rounded-md"
                          >
                            <span className="text-sm">{insurance}</span>
                            <button
                              type="button"
                              className="text-blue-500 hover:text-blue-700"
                              onClick={() => removeInsurance(insurance)}
                            >
                              <X className="h-4 w-4" />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label className="text-sm font-medium">
                      Qualifications
                    </label>
                    <div className="flex gap-2">
                      <input
                        type="text"
                        className="flex-1 px-3 py-2 border rounded-md"
                        placeholder="Add qualification"
                        value={newQualification}
                        onChange={(e) => setNewQualification(e.target.value)}
                      />
                      <Button
                        type="button"
                        variant="outline"
                        onClick={handleAddQualification}
                      >
                        Add
                      </Button>
                    </div>

                    {/* Display added qualifications */}
                    <div className="flex flex-wrap gap-2 pt-2">
                      {formData.qualifications.map((qualification, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-1 bg-blue-50 text-blue-700 px-2 py-1 rounded-md"
                        >
                          <span className="text-sm">{qualification}</span>
                          <button
                            type="button"
                            className="text-blue-500 hover:text-blue-700"
                            onClick={() => handleRemoveQualification(index)}
                          >
                            ×
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 2 && (
                <div className="p-4">
                  {/* Label */}
                  <label className="block text-lg font-semibold mb-2">
                    Add Availability Slots
                  </label>

                  {/* Title */}
                  <p className="text-sm text-gray-600 mb-2">Monday - Friday</p>

                  {/* Time Input Fields (24-hour format) */}
                  <div className="flex gap-4">
                    {/* Start Time */}
                    <input
                      type="time"
                      className="border rounded-md px-3 py-2 text-sm focus:ring focus:ring-blue-300"
                      value={formData.availability.startTime}
                      onChange={(e) => {
                        const newStartTime = e.target.value;
                        setFormData((prev) => ({
                          ...prev,
                          availability: {
                            ...prev.availability,
                            startTime: newStartTime,
                            endTime:
                              prev.availability.endTime < newStartTime
                                ? newStartTime
                                : prev.availability.endTime, // Adjust end time if needed
                          },
                        }));
                      }}
                      required
                    />

                    {/* End Time */}
                    <input
                      type="time"
                      className="border rounded-md px-3 py-2 text-sm focus:ring focus:ring-blue-300"
                      value={formData.availability.endTime}
                      min={formData.availability.startTime} // Prevents selecting an earlier time
                      onChange={(e) => {
                        const newEndTime = e.target.value;
                        if (newEndTime >= formData.availability.startTime) {
                          setFormData((prev) => ({
                            ...prev,
                            availability: {
                              ...prev.availability,
                              endTime: newEndTime,
                            },
                          }));
                        }
                      }}
                      required
                    />
                  </div>
                </div>
              )}

              {currentStep === 3 && (
                <div className="space-y-4">
                  {!submitSuccess ? (
                    <>
                      <h3 className="text-lg font-semibold">
                        Review Information
                      </h3>
                      <div className="bg-gray-50 p-4 rounded-lg space-y-4">
                        <div className="space-y-2">
                          <label className="text-sm font-medium">Name</label>
                          <p className="text-sm text-gray-600">
                            {formData.name}
                          </p>
                        </div>
                        <div className="space-y-2">
                          <label className="text-sm font-medium">Role</label>
                          <p className="text-sm text-gray-600">
                            {formData.role}
                          </p>
                        </div>
                        <div className="space-y-2">
                          <label className="text-sm font-medium">Mobile</label>
                          <p className="text-sm text-gray-600">
                            {formData.contact_number}
                          </p>
                        </div>
                        <div className="space-y-2">
                          <label className="text-sm font-medium">Email</label>
                          <p className="text-sm text-gray-600">
                            {formData.email}
                          </p>
                        </div>
                        <div className="space-y-2">
                          <label className="text-sm font-medium">Bio</label>
                          <p className="text-sm text-gray-600">
                            {formData.bio}
                          </p>
                        </div>
                        <div className="space-y-2">
                          <label className="text-sm font-medium">
                            Insurance
                          </label>
                          <p className="text-sm text-gray-600">
                            {formData.insurance.join(", ")}
                          </p>
                        </div>
                        <div className="space-y-2">
                          <label className="text-sm font-medium">
                            Qualifications
                          </label>
                          <p className="text-sm text-gray-600">
                            {formData.qualifications.join(", ")}
                          </p>
                        </div>
                        <div className="space-y-2">
                          <label className="text-sm font-medium">
                            Availability
                          </label>
                          <div className="text-sm text-gray-700">
                            Monday - Friday:{" "}
                            <span className="font-medium">
                              {formData.availability.startTime} -{" "}
                              {formData.availability.endTime}
                            </span>
                          </div>
                        </div>
                      </div>

                      {submitError && (
                        <div className="bg-red-50 border border-red-200 text-red-600 p-4 rounded-md">
                          Something went wrong. Please try submitting again.
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="bg-green-50 border border-green-200 p-6 rounded-lg text-center">
                      <div className="flex justify-center mb-4">
                        <CheckCircle className="h-12 w-12 text-green-500" />
                      </div>
                      <h3 className="text-lg font-semibold text-green-800 mb-2">
                        Therapist Added Successfully!
                      </h3>
                      <p className="text-green-600 mb-4">
                        An email has been sent to {formData.email} with their
                        login credentials.
                      </p>
                    </div>
                  )}
                </div>
              )}

              <div className="flex justify-between pt-4">
                <div>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() =>
                      currentStep > 0 && setCurrentStep(currentStep - 1)
                    }
                    disabled={
                      currentStep === 0 || isSubmitting || submitSuccess
                    }
                  >
                    Back
                  </Button>
                </div>

                <div className="flex gap-3">
                  {currentStep === 3 ? (
                    submitSuccess ? (
                      <Button
                        type="button"
                        className="bg-blue-600 hover:bg-blue-700 text-white"
                        onClick={handleCloseAddTherapistDialog}
                      >
                        Done
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        className="bg-blue-600 hover:bg-blue-700 text-white"
                        onClick={handleAddTherapist}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                            <Loader2 className="h-4 w-4 animate-spin mr-2" />
                            Adding Therapist...
                          </>
                        ) : (
                          "Add Therapist"
                        )}
                      </Button>
                    )
                  ) : (
                    <Button
                      type="button"
                      className="bg-blue-600 hover:bg-blue-700 text-white"
                      onClick={() => setCurrentStep(currentStep + 1)}
                      disabled={currentStep === 0 && !validateStep0()}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>

      {/* Invite Therapist Dialog */}
      <Dialog
        open={isInviteDialogOpen}
        onOpenChange={(open) => {
          if (!open) {
            handleCloseInviteDialog();
          } else {
            setIsInviteDialogOpen(true);
          }
        }}
      >
        <DialogTrigger asChild>
          <Button
            variant="outline"
            className="border-blue-200 hover:bg-blue-50 flex items-center gap-2"
          >
            <Mail className="h-4 w-4 text-blue-600" />
            Invite Therapist
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-lg h-[400px] overflow-auto">
          <DialogHeader>
            <DialogTitle>Invite Therapist via Email</DialogTitle>
            <DialogDescription>
              Send an invitation email to a therapist to join your practice.
            </DialogDescription>
          </DialogHeader>

          <div className="mt-4 space-y-4">
            {status.type === "success" ? (
              <div className="text-center py-8">
                <div className="text-green-600 text-lg font-medium mb-2">
                  ✓ {status.message}
                </div>
                <Button
                  variant="outline"
                  onClick={handleCloseInviteDialog}
                  className="mt-4"
                >
                  Close
                </Button>
              </div>
            ) : (
              <>
                {status.type === "error" && (
                  <div className="text-red-600 text-sm mb-4">
                    {status.message}
                  </div>
                )}
                <div className="space-y-2">
                  <label className="text-sm font-medium">Email *</label>
                  <input
                    type="email"
                    className="w-full px-3 py-2 border rounded-md"
                    placeholder="Enter therapist's email"
                    value={inviteEmail}
                    onChange={(e) => setInviteEmail(e.target.value)}
                  />
                </div>
                <div className="space-y-2">
                  <label className="text-sm font-medium">Role *</label>
                  <select
                    className="w-full px-3 py-2 border rounded-md border-gray-300"
                    value={inviteRole}
                    onChange={(e) => setInviteRole(e.target.value)}
                  >
                    <option value="" disabled>
                      Select your role
                    </option>
                    {role === "ADMIN" ? (
                      <option value="ADMIN">Admin</option>
                    ) : (
                      ""
                    )}
                    {role === "ADMIN" ? (
                      <option value="SUPERVISOR">Supervisor</option>
                    ) : (
                      ""
                    )}
                    <option value="THERAPIST">Therapist</option>
                  </select>
                </div>
                <Button
                  type="button"
                  className="bg-blue-600 hover:bg-blue-700 text-white w-full flex items-center justify-center"
                  onClick={handleInviteTherapist}
                  disabled={
                    inviteLoading || !isValidEmail(inviteEmail) || !inviteRole
                  }
                >
                  {inviteLoading ? (
                    <Loader2 className="h-4 w-4 animate-spin mr-2" />
                  ) : status.type === "error" ? (
                    <RefreshCw className="h-4 w-4 mr-2" />
                  ) : (
                    <Mail className="h-4 w-4 mr-2" />
                  )}
                  {inviteLoading
                    ? "Sending..."
                    : status.type === "error"
                    ? "Try Again"
                    : "Send Invite"}
                </Button>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddMember;
