import { createSlice } from "@reduxjs/toolkit";

const overviewDashboardSlice = createSlice({
  name: "overview_dashboard",
  initialState: {
    supervisor_id: "",
    team_id: "",
    team_members: [],
    recent_meetings: {
      my_recent_meetings: [],
      team_recent_meetings: [],
    },
    patient_statistics: {
      total_patients: 0,
      high_risk_patients: 0,
      medium_risk_patients: 0,
      low_risk_patients: 0,
    },
    meeting_insights: {
      average_session_score: 0,
      average_client_progress_score: 0,
      latest_insights: [],
    },
    loading: false,
  },
  reducers: {
    setOverviewDashboard: (state, action) => {
      return action.payload;
    },
    setOverviewDashboardLoading: (state, action) => {
      state.loading = action.payload; // Action to toggle loading state
    },
  },
});

export const { setOverviewDashboard, setOverviewDashboardLoading } =
  overviewDashboardSlice.actions;

export default overviewDashboardSlice.reducer;
