import { useEffect, useState } from "react";
import { updateTherapyConcernsByTherapyId } from "../../api/therapist";
import ASSETS from "../../assets/Assets";

const ClientConcernsModal = function ({
  show,
  onClose,
  allConcerns,
  setAllConcerns,
  therapyId,
}) {
  let [allConcernStatus, setAllConcernStatus] = useState([]);

  useEffect(() => {
    setAllConcernStatus(allConcerns);
  }, [allConcerns]);

  function onCheckBoxTick(event, idx) {
    console.log(allConcernStatus);
    setAllConcernStatus((prevAllConcernStatus) => {
      console.log(prevAllConcernStatus);
      let newAllConcernStatus = prevAllConcernStatus.map((element, i) => {
        if (i === idx) {
          if (event.target.checked) {
            element.status = "1";
          } else {
            element.status = "0";
          }
        }
        return element;
      });
      return newAllConcernStatus;
    });
  }

  function onSaveButtonClick() {
    updateTherapyConcernsByTherapyId(allConcernStatus, therapyId).then(
      (res) => {
        setAllConcerns(allConcernStatus);
        onClose();
      }
    );
  }

  if (!show) return null;

  return (
    <div className="modal" onClick={onClose}>
      <div
        className="modal-content add-client-manually-modal-content client-concerns-modal"
        onClick={(e) => e.stopPropagation()}
      >
        <span className="close-modal-btn">
          <img
            src={ASSETS.modalCloseIcon}
            alt="close modal"
            onClick={onClose}
          />
        </span>
        <div className="modal-title">
          <div className="modal-heading">Add Concerns</div>
        </div>
        <div className="modal-body add-client-modal-manually">
          <div className="row concerns-section-header">
            <div className="concerns-section-heading">
              Pick the concerns your client is facing to keep a record of their
              concerns
            </div>
            {/* <div className="add-new-text">Add New</div> */}
          </div>
        </div>
        <div className="health-concerns-heading">Physical Health Concerns:</div>
        <div className="concerns-checkbox-container">
          {allConcernStatus.map((element, index) => {
            return (
              <div key={element.concern_id}>
                <input
                  type="checkbox"
                  checked={element.status == "1" ? true : false}
                  onChange={(event) => onCheckBoxTick(event, index)}
                />
                <div className="health-concerns">{element.concern}</div>
              </div>
            );
          })}
        </div>
        <div className="concerns-modal-save-button" onClick={onSaveButtonClick}>
          Save
        </div>
      </div>
    </div>
  );
};

export default ClientConcernsModal;
