import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthenticator } from "@aws-amplify/ui-react";
import TherapistAuthPage from "../../../screens/auth/therapist/TherapistAuthPage";
import { getTherapistByEmailIdAPI } from "../../../api/therapist";
import {
  logoutResetStore,
  setTherapist,
} from "../../../store/therapist/therapistSlice";
import { updateTherapistStatusAPI } from "../../../api/therapist";
import { ThreeDots } from "react-loader-spinner";

function TherapistAuthApp({ App }) {
  const { user, signOut, authStatus } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authStatus === "authenticated") {
      getTherapistByEmailIdAPI(user?.attributes?.email)
        .then((data) => {
          // if status is INVITED update ACTIVE
          if (data.status === "INVITED") {
            updateTherapistStatusAPI(data["therapist_id"], "ACTIVE");
            // update data status
            data["status"] = "ACTIVE";
          }
          dispatch(setTherapist(data));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [authStatus, user]);

  const logOut = () => {
    setLoading(true);
    signOut();
    dispatch(logoutResetStore());
  };

  return (
    <>
      {authStatus === "authenticated" && !loading ? (
        <App logOut={logOut} />
      ) : authStatus === "configuring" ||
        (authStatus === "authenticated" && loading) ? (
        <div className="ring-loader">
          <ThreeDots
            height="100"
            width="100"
            color="#5F6CE1"
            radius="50"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />
        </div>
      ) : (
        <TherapistAuthPage />
      )}
    </>
  );
}

export default TherapistAuthApp;
