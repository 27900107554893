import { createSlice } from "@reduxjs/toolkit";

const clientDashboardSlice = createSlice({
  name: "client_dashboard",
  initialState: {
    risk_summary: {
      HIGH: 0,
      MEDIUM: 0,
      LOW: 0,
    },
    therapy_status: {
      ACTIVE: 0,
      ON_HOLD: 0,
      INVITED: 0,
      TERMINATED: 0,
    },
    sessions_per_day: {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0,
    },
    patients: [],
    compatibilityScore: 0,
    loading: false,
  },
  reducers: {
    setClientDashboard: (state, action) => {
      return action.payload;
    },
    setClientDashboardLoading: (state, action) => {
      state.loading = action.payload; // Action to toggle loading state
    },
    setCompatibilityScore: (state, action) => {
      state.compatibilityScore = action.payload; // Update compatibility score
    },
    addPatient: (state, action) => {
      state.patients.push(action.payload); // Append new patient to the array
    },
  },
});

export const {
  setClientDashboard,
  setClientDashboardLoading,
  setCompatibilityScore,
  addPatient,
} = clientDashboardSlice.actions;

export default clientDashboardSlice.reducer;
