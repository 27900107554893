import "./CreateClientByNameModal.css";
import ASSETS from "../../assets/Assets";

import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { createClientUsingNameAPI } from "../../api/therapist";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { addPatient } from "../../store/clientDashboard/clientDashboardSlice";

const CreateClientByName = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const [createClient, setCreateClient] = useState(false);
  const [createClientIsSuccess, setCreateClientIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state

  const therapistId = useSelector((store) => store.therapist.therapist_id);

  const formSchema = yup.object({ name: yup.string().required() }).required();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchema) });

  if (!show) return null;

  const createClientCallback = async (data) => {
    setLoading(true); // Start loading
    try {
      const response = await createClientUsingNameAPI(data.name);
      if (response.status === 200 || response.status === 201) {
        //Get data from response
        const data = response["data"];

        const patientData = {
          patient_name: data["name"],
          patient_id: data["patient_id"],
          therapy_status: "ACTIVE",
          therapy_id: data["therapy_id"],
          last_session: null,
          next_session: null,
          risk_level: null,
          rt_risk_level: null,
        };
        //dispatch
        dispatch(addPatient(patientData));

        setCreateClientIsSuccess(true);
        toast.success("Client added successfully");
      }
    } catch (e) {
      console.log(e);
      toast.error("Failed to add client");
      setCreateClientIsSuccess(false);
    } finally {
      setCreateClient(true);
      setLoading(false); // Stop loading
    }
  };

  const resetStateAndCloseModal = (e) => {
    setCreateClient(false);
    setCreateClientIsSuccess(false);
    onClose(e);
  };

  return (
    <div className="modal" onClick={resetStateAndCloseModal}>
      <div
        className="modal-content scroll-remove"
        onClick={(e) => e.stopPropagation()}
      >
        <span className="close-modal-btn">
          <img
            src={ASSETS.modalCloseIcon}
            alt="close modal"
            onClick={resetStateAndCloseModal}
          />
        </span>
        <div className="modal-header">
          <div className="modal-title">
            <div className="modal-heading">Add Client</div>
            <div className="modal-header-text">
              Simply add a client by entering their full name. After that, you
              can start a real-time or zoom session with them. It's that easy!
            </div>
          </div>
        </div>
        <div className="modal-content-divider" />
        <div className="modal-body">
          <div className="modal-subheading sub-heading">Name</div>
          <form onSubmit={handleSubmit(createClientCallback)}>
            {!createClient && (
              <div className="email-invite-row row">
                <input
                  type="text"
                  className="text-input-field"
                  placeholder="Your client's name"
                  {...register("name", { required: "Name is required" })}
                  style={{ marginTop: "0px", marginBottom: "0px" }}
                  disabled={loading} // Disable input when loading
                />

                <input
                  type="submit"
                  className={`add-client-btn ${loading ? "btn-disabled" : ""}`}
                  value={loading ? "Adding..." : "Add Client"}
                  disabled={loading} // Disable button when loading
                />
              </div>
            )}

            {createClient && (
              <div
                className={
                  createClientIsSuccess
                    ? "invite-sent-confirmation"
                    : "invite-sent-failed"
                }
              >
                {createClientIsSuccess ? "Added!" : "Failed!"}
              </div>
            )}
            {errors.name && (
              <div className="info-text-danger form-error-message">
                {errors.name?.message}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateClientByName;
