export const toolTipText = {
  overview: {
    priority: "Here are the important notifications for today",
    risk: "Factors like suicidal ideation, symptom escalation, self-harm behaviors, interpersonal conflicts etc considered for risk assessment of a client.",
    activeCases: "Number of active patients taking therapy",
    clientProgress:
      "Progress score reflects client improvements based on therapy notes, considering indicators like symptom changes, coping skills, and engagement.",
    sessionScore: "Overall session score",
    teamMembersNumber: "Number of therapists including you under your guidance",
    upcomingSessions: "List of upcoming sessions of you and your team members",
    teamMembersDetails: "Detailed view of your team members",
  },
  client: {
    riskDistribution: "Risk distribution of your patients",
    sessionCompletionRate:
      "Comparison between scheduled and completed sessions over last 4 weeks",
    clientStatusOverview: "Distribution of patient status",
    weeklySessionTrends: "Number of completed sessions in a week",
    compatibilityScore: "Average compatibility score across all the patients",
  },
};
