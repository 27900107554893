import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const addTherapistViaInvite = async (
  org_id,
  inviter_id,
  invitee_role,
  invitee_email
) => {
  const baseURL = config.API_URL;
  const path = "/invite";
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.post(
    baseURL + path,
    {
      org_id,
      inviter_id,
      invitee_role,
      invitee_email,
    },
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
  return res.data;
};

export const addTherapistManually = async (
  user_id,
  org_id,
  role,
  user_created
) => {
  const baseURL = config.API_URL;
  const path = "/therapist/manually";
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.post(
    baseURL + path,
    {
      user_id,
      org_id,
      role,
      user_created,
    },
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
  return res.data;
};
