// RealtimeSession.jsx
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../components/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/card";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
} from "../../components/alert-dialog";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/dialog";
import { Play, Pause, Square, Mic, Clock, Calendar } from "lucide-react";
import { useStopwatch } from "react-timer-hook";
import MicrophoneStream from "microphone-stream";

// Import your realtime API functions
import {
  createRealtimeSession,
  saveRealtimeTranscript,
  saveRealtimeAudioAPI,
  updateDurationOfRealtimeSession,
  createAiReportWithTranscript,
  highlightsAndQuestionsFromTranscript,
  determineRoleFromTranscript,
  generateClientSentimentScoreAPI,
  getRealtimeAudioUploadURLAPI,
} from "../../api/realtime";

// (Optional) Redux actions – for example, to store the live session ID or clear transcript.
import {
  setLiveSessionId,
  clearTranscript,
  // setSpeakerLabel,
  // setMicrophoneStatus,
  addClientSentimentScore,
  // setTranscriptArr,
} from "../../store/liveSession/liveSessionSlice";
import { useAudioRecorder } from "react-audio-voice-recorder";
import {
  convertTranscriptArrForSaving,
  generateTranscriptTextFromArray,
  replaceMultipleStrings,
  stripTimestampFromTranscript,
} from "../dashboard/components/clientChart/RealtimePanel/TranscriptPanelUtls";
import { EventStreamMarshaller } from "@aws-sdk/eventstream-marshaller";
import { fromUtf8, toUtf8 } from "@aws-sdk/util-utf8-node";
import { processAiReport } from "../dashboard/components/clientChart/clientChart";
import { formatDateToYYYYMMDDHHMMSS } from "../../utils/helpers";
import { uploadFiles } from "../../api/s3";
import ProgressNoteModal from "./ProgressNoteModal";
import { format } from "date-fns";

// ===== Helper functions (from your older components) =====

const SAMPLE_RATE = 44100; // input sample rate
let sampleRate = SAMPLE_RATE;
const eventStreamMarshaller = new EventStreamMarshaller(toUtf8, fromUtf8);

// Downsample the audio buffer to 16kHz
const downsampleBuffer = (
  buffer,
  inputSampleRate = SAMPLE_RATE,
  outputSampleRate = 16000
) => {
  if (outputSampleRate === inputSampleRate) return buffer;
  const sampleRateRatio = inputSampleRate / outputSampleRate;
  const newLength = Math.round(buffer.length / sampleRateRatio);
  const result = new Float32Array(newLength);
  let offsetResult = 0;
  let offsetBuffer = 0;
  while (offsetResult < result.length) {
    const nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
    let accum = 0,
      count = 0;
    for (let i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
      accum += buffer[i];
      count++;
    }
    result[offsetResult] = accum / count;
    offsetResult++;
    offsetBuffer = nextOffsetBuffer;
  }
  return result;
};

// PCM encode the float audio data
const pcmEncode = (input) => {
  const buffer = new ArrayBuffer(input.length * 2);
  const view = new DataView(buffer);
  let offset = 0;
  for (let i = 0; i < input.length; i++, offset += 2) {
    const s = Math.max(-1, Math.min(1, input[i]));
    view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
  return buffer;
};

const RealtimeSessionContent = ({
  clientName,
  therapistId,
  therapyId,
  onClose,
}) => {
  // const [sessionStartButton, setSessionStartButton] = useState(false);
  const [clickedChip, setClickedChip] = useState(-1);
  const audioChunksRef = useRef([]);
  const [isRecording, setIsRecording] = useState(false);

  const microphoneStreamRef = useRef(undefined);
  const inputSampleRateRef = useRef(undefined);
  const socketRef = useRef(undefined);
  const dispatch = useDispatch();
  const transcript = useSelector((store) => store.liveSession.transcript);
  // const transcriptArr = useSelector((store) => store.liveSession.transcriptArr);
  const [transcriptArr, setTranscriptArr] = useState([]);

  const transcriptLang = useSelector(
    (store) => store.liveSession.transcriptLang
  );
  const [recordingPaused, setRecordingPaused] = useState(false);

  const transcriptLineCountRef = useRef(0);
  const TRANSCRIPT_LINE_COUNT_THRESHOLD = 3;

  const [highlights, setHighlights] = useState([]);
  const [newHighlights, setNewHighlights] = useState([]);
  const [showProcessing, setShowProcessing] = useState(false);

  const [reportProgress, setReportProgress] = useState(0);

  const [dataForModal, setDataForModal] = useState({});

  const [sessionStartTime, setSessionStartTime] = useState(null);

  const therapist_id = useSelector((store) => {
    return store.therapist.therapist_id;
  });

  // const speakerLabel = useSelector((store) => {
  //   return store.liveSession.speakerLabel;
  // });
  const [speakerLabel, setSpeakerLabels] = useState({});
  const speakerLabelRef = useRef(speakerLabel);
  const lastSpeakerRef = useRef("");
  const currentSentenceRef = useRef("");
  const currentStartTimeRef = useRef(0);
  const currentEndTimeRef = useRef(0);
  const speakerTimeoutRef = useRef(null);

  useEffect(() => {
    speakerLabelRef.current = speakerLabel;
  }, [speakerLabel]);

  const stopWatch = useStopwatch();
  const stopWatch2 = useStopwatch();
  const pausedRef = useRef(false);
  const pausedOffset = useRef(0);

  const recorder = useAudioRecorder();

  useEffect(() => {
    if (
      transcriptArr &&
      transcriptArr.length > 0 &&
      transcriptArr.length - transcriptLineCountRef.current >=
        TRANSCRIPT_LINE_COUNT_THRESHOLD
    ) {
      transcriptLineCountRef.current = transcriptArr.length;
      highlightsAndQuestionsFromTranscript(
        therapist_id,
        generateTranscriptTextFromArray(transcriptArr, speakerLabel)
      )
        .then((res) => {
          const highlight_data = res.data;
          highlight_data.forEach((obj) => {
            if (obj.hasOwnProperty("highlight")) {
              obj.highlight = obj.highlights;
              delete obj.highlights;
            }
          });

          const newHighlights = res.data;
          // console.log(newHighlights);

          setHighlights((prevHighlights) => [
            ...prevHighlights,
            ...newHighlights,
          ]);
          setNewHighlights(newHighlights);
        })
        .catch((err) => {
          console.error(err);
        });

      determineRoleFromTranscript(
        therapist_id,
        generateTranscriptTextFromArray(transcriptArr)
      )
        .then((res) => {
          // console.log("Diarized data: ", res.data)
          // dispatch(setSpeakerLabel(res.data));
          setSpeakerLabels(res.data);
        })
        .catch((err) => {
          console.log("error is : ", err);
        });
    }
  }, [transcriptArr]);

  // const handleChipClick = (index) => {
  //   setClickedChip(index);
  // };

  const stopRecording = () => {
    if (microphoneStreamRef.current) {
      console.log("Recording stopped");
      microphoneStreamRef.current.stop();
      microphoneStreamRef.current.destroy();
      microphoneStreamRef.current = undefined;
      // setSessionStartButton(false);
    }
    recorder?.stopRecording();
  };

  const startRecording = async () => {
    recorder.startRecording();
    setSessionStartTime(new Date());
    // if (microphoneStreamRef.current) {
    //   stopRecording();
    //   recorder?.stopRecording();
    //   return;
    // }
    await createSocketConnection();
    microphoneStreamRef.current = new MicrophoneStream();
    microphoneStreamRef.current.on("format", (data) => {
      inputSampleRateRef.current = data.sampleRate;
    });
    microphoneStreamRef.current.setStream(
      await window.navigator.mediaDevices.getUserMedia({
        video: false,
        audio: {
          echoCancellation: false,
          noiseSuppression: false,
        },
      })
    );
    // setSessionStartButton(true);
    // dispatch(setMicrophoneStatus(true));
    stopWatch2.start();
  };

  const createSocketConnection = async () => {
    socketRef.current = new WebSocket(
      // `ws://localhost:5001/listen/aws-transcribe?language=${transcriptLang}`
      `wss://websocket.therapist.therawin.health/listen/aws-transcribe?language=${transcriptLang}`
      // `wss://websocket.therapist.therawin.health/listen/aws-transcribe?language=en-US`
    );
    socketRef.current.onmessage = async function (message) {
      const data = JSON.parse(message.data);
      if (data.event === "aws-connected") {
        setIsRecording(true);
        microphoneStreamRef.current.on("data", function (rawAudioChunk) {
          let binary = convertAudioToBinaryMessage(rawAudioChunk);
          socketRef.current.send(binary);
          audioChunksRef.current.push(binary);
        });
      } else {
        const newTranscript = createTranscript(data);
      }
    };

    socketRef.current.onerror = function (error) {
      console.log("WebSocket connection error. Try again.", error);
    };
  };

  // const generateSentimentScore = () => {
  //   if (
  //     currentSentenceRef.current &&
  //     currentStartTimeRef.current !== currentEndTimeRef.current
  //   ) {
  //     if (
  //       speakerLabelRef.current?.client?.length > 0 &&
  //       speakerLabelRef.current.client.some(
  //         (label) => label === lastSpeakerRef.current
  //       )
  //     ) {
  //       generateClientSentimentScoreAPI(
  //         stripTimestampFromTranscript(currentSentenceRef.current),
  //         currentEndTimeRef.current,
  //         currentStartTimeRef.current
  //       )
  //         .then((res) => {
  //           const data = res.data;
  //           dispatch(
  //             addClientSentimentScore({
  //               time: data.time - pausedOffset.current,
  //               score: data.score,
  //             })
  //           );
  //         })
  //         .catch((err) => {
  //           console.error(err);
  //         });
  //     }
  //   }
  // };
  const createTranscript = (transcriptionData) => {
    let currentSpeaker = lastSpeakerRef.current;
    if (transcriptionData.length === 0) return;
    let currArr = [];

    transcriptionData.forEach((entry) => {
      console.log(entry);
      const speaker = `Speaker ${entry.speaker}`;

      if (speakerTimeoutRef.current) clearTimeout(speakerTimeoutRef.current);
      speakerTimeoutRef.current = setTimeout(() => {
        // generateSentimentScore();
        lastSpeakerRef.current = "";
        currentSentenceRef.current = "";
        currentStartTimeRef.current = 0;
        currentEndTimeRef.current = 0;
      }, 10 * 1000);

      if (currentSpeaker !== speaker) {
        // Add speaker information only when it changes
        currArr.push({
          speaker,
          content: entry.sentence,
          start: entry.start - pausedOffset.current,
          end: entry.end - pausedOffset.current,
          type: "speaker-change",
          chat_time: Math.floor(Date.now() / 1000),
        });
        // generateSentimentScore();
        currentSpeaker = speaker;
        lastSpeakerRef.current = speaker;
        currentSentenceRef.current = `\n${speaker}:`;
        currentStartTimeRef.current = entry.start;
      } else {
        currArr.push({
          content: entry.sentence,
          end: entry.end - pausedOffset.current,
          type: "same-speaker",
          chat_time: Math.floor(Date.now() / 1000),
        });
      }

      currentEndTimeRef.current = entry.end;
      currentSentenceRef.current += " " + entry.sentence;
    });
    setTranscriptArr((prev) => {
      const changeArr = currArr;
      const prevArr = [...prev];
      changeArr.forEach((item) => {
        if (item.type === "speaker-change") {
          prevArr.push({
            speaker: item.speaker,
            start: item.start,
            end: item.end,
            content: item.content,
            chat_time: item.chat_time.toString(),
          });
        } else {
          prevArr[prevArr.length - 1].content += " " + item.content;
          prevArr[prevArr.length - 1].end = item.end;
          prevArr[prevArr.length - 1].chat_time = item.chat_time.toString();
        }
      });
      return prevArr;
    });
    // dispatch(setTranscriptArr(currArr));
  };

  const convertAudioToBinaryMessage = (audioChunk) => {
    let raw = MicrophoneStream.toRaw(audioChunk);
    if (pausedRef.current) raw.fill(0);

    if (raw == null) return;
    // console.log("inputSampleRateRef.current", inputSampleRateRef.current)
    let downsampledBuffer = downsampleBuffer(
      raw,
      inputSampleRateRef.current,
      sampleRate
    );
    let pcmEncodedBuffer = pcmEncode(downsampledBuffer);

    return pcmEncodedBuffer;
  };

  // const onSessionStartClick = () => {
  //   setSessionStartButton(!sessionStartButton);
  // };

  const saveRealtimeAudio = async (real_time_session_id) => {
    let file;
    let audio_type;

    if (audioChunksRef.current.length > 0) {
      file = new Blob([...audioChunksRef.current], {
        type: "application/octet-stream",
      });
      audio_type = "pcm";
    } else {
      console.warn("No audio chunks to upload.");
      return;
    }

    let response = await getRealtimeAudioUploadURLAPI(
      therapyId,
      real_time_session_id,
      audio_type
    );

    const data = response.data;
    const presignedUrl = data.url;

    await uploadFiles(presignedUrl, file);
    const res = await saveRealtimeAudioAPI(
      real_time_session_id,
      data.bucket,
      data.key
    );
  };

  const [realtimeReportModal, setRealtimeReportModal] = useState(false);

  const onRealtimeReportModalClose = () => {
    setRealtimeReportModal(!realtimeReportModal);
    onClose();
  };

  const createDataFormatForModal = (data) => {
    const newData = [];
    Object.keys(data).forEach((item, index) =>
      newData.push({ title: item, content: data[item] })
    );
    return newData;
  };
  const realTimeSessionIdRef = useRef("");
  const processRealtimeSession = async (transcript) => {
    // this should be the session close button
    if (transcript.length !== 0) {
      setShowProcessing(true);
      const res = await createRealtimeSession(therapist_id, therapyId);
      const temp_real_time_session_id = res.data.real_time_session_id;
      realTimeSessionIdRef.current = temp_real_time_session_id;
      // dispatch(setLiveSessionId(temp_real_time_session_id));
      // dispatch(setNoteId(res.data.progress_note_id));
      const note_id = res.data.progress_note_id;

      console.log("temp_real_time_session_id", temp_real_time_session_id);

      const reports = [
        createAiReportWithTranscript(
          therapist_id,
          replaceMultipleStrings(
            stripTimestampFromTranscript(transcript),
            speakerLabel
          ),
          "SOAP",
          temp_real_time_session_id
        ),
        createAiReportWithTranscript(
          therapist_id,
          replaceMultipleStrings(
            stripTimestampFromTranscript(transcript),
            speakerLabel
          ),
          "DAP",
          temp_real_time_session_id
        ),
        createAiReportWithTranscript(
          therapist_id,
          replaceMultipleStrings(
            stripTimestampFromTranscript(transcript),
            speakerLabel
          ),
          "BIRP",
          temp_real_time_session_id
        ),
        createAiReportWithTranscript(
          therapist_id,
          replaceMultipleStrings(
            stripTimestampFromTranscript(transcript),
            speakerLabel
          ),
          "GIRP",
          temp_real_time_session_id
        ),
      ];

      const promisesWithIndex = reports.map((promise, index) => ({
        promise,
        index,
      }));

      const allPromises = promisesWithIndex.map(({ promise, index }) =>
        promise
          .then((result) => {
            setReportProgress((prevProgress) => prevProgress + 1);
            console.log(`Task ${index} finished:`, result);
            return { index, result };
          })
          .catch((error) => {
            setReportProgress((prevProgress) => prevProgress + 1);
            console.error(`Task ${index} failed:`, error);
            throw error;
          })
      );

      // save_sentiment_scores(temp_real_time_session_id)
      //   .then((data) => {
      //     console.log(data);
      //   })
      //   .catch((err) => console.log(err));

      Promise.all(allPromises)
        .then((fulfilledResults) => {
          // All promises have resolved
          console.log("All tasks finished:", fulfilledResults);

          fulfilledResults.forEach((response, index) => {
            console.log(
              `This is the Response ${index + 1}: `,
              response.result.data
            );
          });

          let data = { notes: {} };

          const {
            progressNotesFormatted: soapNotesFormatted,
            suggestionsFormatted: soapSuggestionsFormatted,
          } = processAiReport(fulfilledResults[0].result.data, "SOAP");

          data["notes"]["SOAP"] = createDataFormatForModal(soapNotesFormatted);

          const {
            progressNotesFormatted: dapNotesFormatted,
            suggestionsFormatted: dapSuggestionsFormatted,
          } = processAiReport(fulfilledResults[1].result.data, "DAP");
          data["notes"]["DAP"] = createDataFormatForModal(dapNotesFormatted);

          const {
            progressNotesFormatted: birpNotesFormatted,
            suggestionsFormatted: birpSuggestionsFormatted,
          } = processAiReport(fulfilledResults[2].result.data, "BIRP");
          data["notes"]["BIRP"] = createDataFormatForModal(birpNotesFormatted);

          const {
            progressNotesFormatted: girpNotesFormatted,
            suggestionsFormatted: girpSuggestionsFormatted,
          } = processAiReport(fulfilledResults[3].result.data, "GIRP");
          data["notes"]["GIRP"] = createDataFormatForModal(girpNotesFormatted);
          setDataForModal(data);
          setShowProcessing(false);
          setRealtimeReportModal(true);
          // dispatch(clearTranscript());
          // dispatch(setLiveSessionId(null));
          setReportProgress(0);
        })
        .catch((error) => {
          console.log("The error is: ", error);
          // dispatch(clearTranscript());
          // dispatch(setLiveSessionId(null));
          setShowProcessing(false);
          setReportProgress(0);
          alert("Something went wrong");
        });

      console.log(
        "Session start time is: ",
        formatDateToYYYYMMDDHHMMSS(sessionStartTime)
      );
      console.log(
        "Session end time is: ",
        formatDateToYYYYMMDDHHMMSS(new Date())
      );
      updateDurationOfRealtimeSession(
        temp_real_time_session_id,
        formatDateToYYYYMMDDHHMMSS(sessionStartTime),
        formatDateToYYYYMMDDHHMMSS(new Date())
      );

      let res1 = await saveRealtimeTranscript(
        therapist_id,
        therapyId,
        temp_real_time_session_id,
        convertTranscriptArrForSaving(transcriptArr, speakerLabel),
        "REALTIME"
      );
      await saveRealtimeAudio(temp_real_time_session_id);
      console.log("Saved the transcript: ", res1);

      // save_sentiment_scores(temp_real_time_session_id)
      //   .then((data) => {
      //     console.log(data);
      //   })
      //   .catch((err) => console.log(err));
    }
  };
  const transcriptsEndRef = useRef(null);
  const scrollToBottom = () => {
    transcriptsEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [transcriptArr]);

  const onRecordingStop = async () => {
    stopRecording();
    recorder?.stopRecording();
    socketRef.current.close();
    socketRef.current = null;
    stopWatch.reset();
    stopWatch2.reset();
    setIsRecording(false);
    await processRealtimeSession(
      generateTranscriptTextFromArray(transcriptArr, speakerLabel)
    );
  };

  const onRecordingPause = () => {
    // microphoneStreamRef.current.pauseRecording();
    setRecordingPaused(true);
    pausedRef.current = true;
    recorder.stopRecording();
    stopWatch.start();
    stopWatch2.pause();
  };

  const onRecordingResume = () => {
    // microphoneStreamRef.current.playRecording();
    setRecordingPaused(false);
    pausedOffset.current = stopWatch.totalSeconds;
    pausedRef.current = false;
    recorder.startRecording();
    stopWatch.pause();
    stopWatch2.start();
    // dispatch(setMicrophoneStatus(true));
    // clearInterval(pauseIntervalRef.current);
  };
  const handlePlay = () => {
    startRecording();
  };

  const handlePause = () => {
    onRecordingPause();
  };

  const handleResume = () => {
    onRecordingResume();
  };

  const handleStop = () => {
    onRecordingStop();
  };
  return (
    <div className=" bg-gray-50 p-6">
      <div className="max-w-7xl mx-auto space-y-6">
        {/* Recording Controls */}
        <Card className="bg-white">
          <CardContent className="py-6">
            <div className="flex justify-between items-center">
              {/* Client Info and Session Details */}
              <div className="space-y-2">
                <div className="flex items-center gap-3">
                  <h2 className="text-xl font-semibold text-gray-900">
                    {clientName || "Client Name"}
                  </h2>
                </div>
                <div className="flex items-center gap-4 text-sm text-gray-600">
                  <div className="flex items-center gap-2">
                    <Clock className="h-4 w-4" />
                    {isRecording ? (
                      <span>
                        {`${stopWatch2.hours
                          .toString()
                          .padStart(2, "0")}:${stopWatch2.minutes
                          .toString()
                          .padStart(2, "0")}:${stopWatch2.seconds
                          .toString()
                          .padStart(2, "0")}`}
                      </span>
                    ) : (
                      <span>Session duration: 0:00</span>
                    )}
                  </div>
                  <div className="flex items-center gap-2">
                    <Calendar className="h-4 w-4" />
                    <span>{new Date().toLocaleDateString()}</span>
                  </div>
                </div>
                {isRecording && (
                  <div className="flex items-center gap-2 text-sm">
                    <Mic
                      className={`h-4 w-4 ${
                        !recordingPaused
                          ? "text-red-500 animate-pulse"
                          : "text-gray-500"
                      }`}
                    />
                    <span className="text-gray-600 font-medium">
                      {recordingPaused
                        ? "Recording Paused"
                        : "Recording in Progress"}
                    </span>
                  </div>
                )}
              </div>
              {/* Audio Visualization & Controls */}
              <div className="flex items-center gap-4">
                {/* (Optionally add an audio visualizer here) */}
                {isRecording && !recordingPaused && (
                  <div className="w-48 h-12 bg-gray-50 rounded-lg flex items-center justify-center">
                    <div className="flex items-center gap-1">
                      {[...Array(12)].map((_, i) => (
                        <div
                          key={i}
                          className="w-1 bg-blue-500 rounded-full animate-pulse"
                          style={{
                            height: `${Math.random() * 32 + 8}px`,
                            animationDelay: `${i * 0.1}s`,
                          }}
                        />
                      ))}
                    </div>
                  </div>
                )}

                <div className="flex items-center gap-3 bg-gray-100 p-3 rounded-full shadow-sm">
                  {isRecording ? (
                    <>
                      {recordingPaused ? (
                        <Button
                          onClick={handleResume}
                          className="h-12 w-12 rounded-full bg-blue-500 hover:bg-blue-600 flex items-center justify-center"
                        >
                          <Play className="h-6 w-6 text-white" />
                        </Button>
                      ) : (
                        <Button
                          onClick={handlePause}
                          className="h-12 w-12 rounded-full bg-blue-500 hover:bg-blue-600 flex items-center justify-center"
                        >
                          <Pause className="h-6 w-6 text-white" />
                        </Button>
                      )}
                      <Button
                        onClick={handleStop}
                        className="h-12 w-12 rounded-full bg-red-500 hover:bg-red-600 flex items-center justify-center"
                      >
                        <Square className="h-6 w-6 text-white" />
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={handlePlay}
                      className="h-12 w-12 rounded-full bg-blue-500 hover:bg-blue-600 flex items-center justify-center"
                    >
                      <Play className="h-6 w-6 text-white" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Main Content Grid */}
        <div className="grid grid-cols-5 gap-6">
          {/* Suggested Questions */}
          <div className="col-span-3">
            <Card className="h-[calc(100vh-400px)] flex flex-col">
              <CardHeader className="border-b bg-blue-50">
                <CardTitle className="text-xl text-blue-800">
                  Suggested Questions
                </CardTitle>
              </CardHeader>
              <CardContent className="p-6 flex-1 overflow-y-auto">
                <div className="space-y-4 h-full overflow-y-auto">
                  {/* Replace with dynamic questions if needed */}
                  {newHighlights &&
                    newHighlights.map((highlight, i) => (
                      <div
                        className="p-4 rounded-lg border-l-4 bg-gray-50 border-gray-300"
                        key={i}
                      >
                        <div className="flex justify-between items-start mb-2">
                          <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm font-medium">
                            {highlight.highlights}
                          </span>
                        </div>
                        {highlight.questions.map((question, i) => (
                          <p className="text-gray-800 text-lg" key={i}>
                            {question}
                          </p>
                        ))}
                      </div>
                    ))}
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Live Transcription */}
          <div className="col-span-2">
            <Card className="h-[calc(100vh-400px)] flex flex-col">
              <CardHeader className="border-b">
                <CardTitle>Live Transcription</CardTitle>
              </CardHeader>
              <CardContent className="p-4 flex-1 overflow-y-auto">
                <div className="h-full overflow-y-auto space-y-2">
                  {transcriptArr.length > 0 ? (
                    <div className="scroll-thin-vertical bg-white border border-gray-200 rounded-2xl p-4 overflow-x-hidden overflow-y-auto break-words flex flex-col gap-2 w-full h-full">
                      {transcriptArr.map((item, index) => {
                        if (
                          speakerLabel?.therapist?.some(
                            (label) => label === item.speaker
                          )
                        ) {
                          return (
                            <div
                              key={index}
                              className="bg-blue-50 text-blue-80 border border-gray-300 rounded-xl p-4 w-fit max-w-[90%] ml-auto"
                            >
                              <p className="text-gray-500 font-semibold text-lg m-0">
                                You
                              </p>
                              <p className="m-0">{item.content}</p>
                            </div>
                          );
                        } else {
                          const speaker = speakerLabel?.client?.some(
                            (label) => label === item.speaker
                          )
                            ? "client"
                            : item.speaker;
                          return (
                            <div
                              key={index}
                              className="bg-gray-100 text-gray-800 border border-gray-300 rounded-xl p-4 w-fit max-w-[90%]"
                            >
                              <p className="text-gray-500 font-semibold text-lg m-0">
                                {speaker.charAt(0).toUpperCase() +
                                  speaker.slice(1)}
                              </p>
                              <p className="m-0">{item.content}</p>
                            </div>
                          );
                        }
                      })}
                      <div ref={transcriptsEndRef} />
                    </div>
                  ) : (
                    <div className="text-gray-500">No transcript yet.</div>
                  )}
                </div>
              </CardContent>
            </Card>
          </div>
        </div>

        {/* Processing Alert */}
        {realtimeReportModal ? (
          <ProgressNoteModal
            editable={true}
            data={dataForModal}
            sessionDate={format(sessionStartTime, "do MMM, yyyy")}
            isOpen={realtimeReportModal}
            onClose={onRealtimeReportModalClose}
            realTimeSessionId={realTimeSessionIdRef.current}
          />
        ) : (
          showProcessing && (
            <AlertDialog open={showProcessing}>
              <AlertDialogContent className="text-center">
                <AlertDialogTitle className="text-xl font-semibold">
                  Processing Session
                </AlertDialogTitle>
                <AlertDialogDescription className="space-y-4">
                  <div className="flex justify-center my-6">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500" />
                  </div>
                  <p className="text-gray-600">
                    AI is analyzing the session and generating progress notes.
                    This may take a few moments...
                  </p>
                </AlertDialogDescription>
              </AlertDialogContent>
            </AlertDialog>
          )
        )}
      </div>
    </div>
  );
};

// The outer dialog component that wraps the session content
const RealtimeSession = ({
  isOpen,
  onClose,
  clientName,
  therapistId,
  therapyId,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-6xl h-[90vh]">
        <DialogHeader>
          <DialogTitle>Realtime Session</DialogTitle>
        </DialogHeader>
        <div className="overflow-y-auto">
          <RealtimeSessionContent
            clientName={clientName}
            therapistId={therapistId}
            therapyId={therapyId}
            onClose={onClose}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RealtimeSession;
