import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/tabs";
import { Users, Clock, Calendar, Award, Shield, Phone } from "lucide-react";
import { format, parse } from "date-fns";
import { ChevronLeft } from "lucide-react";

const TeamMember = ({ selectedTherapist, handleOnClickBack }) => {
  const renderClientsList = (therapist) => (
    <Card>
      <CardHeader>
        <CardTitle>Active Clients ({therapist.patients.length})</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="divide-y">
          {therapist.patients.map((client, index) => (
            <div key={index} className="py-3 flex justify-between">
              <span className="font-medium">{client.patient_name}</span>
              <span className="text-gray-600">
                Since {new Date(client.connected_since).toLocaleDateString()}
              </span>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );

  const renderAvailability = (therapist) => {
    const dayOrder = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    return (
      <Card>
        <CardHeader>
          <CardTitle>Available Time Slots</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            {therapist.available_slots
              ?.slice()
              .sort((a, b) => {
                return (
                  dayOrder.indexOf(a.dayOfWeek) - dayOrder.indexOf(b.dayOfWeek)
                );
              })
              .map((slot, index) => (
                <div key={index} className="flex items-center text-gray-700">
                  <Clock className="w-4 h-4 mr-2 text-blue-600" />
                  {slot.dayOfWeek}{" "}
                  {`${format(
                    parse(slot.start_time, "HH:mm:ss.SSSSSS", new Date()),
                    "h:mm a"
                  )} -
                ${format(
                  parse(slot.end_time, "HH:mm:ss.SSSSSS", new Date()),
                  "h:mm a"
                )}`}
                </div>
              ))}
          </div>
        </CardContent>
      </Card>
    );
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen w-full">
      <div className="mb-8 heading">
        <ChevronLeft
          size={24}
          onClick={handleOnClickBack}
          style={{ cursor: "pointer" }}
        />
        <div className="ml-2">Team Member</div>
      </div>
      <div className="mx-auto">
        {selectedTherapist ? (
          <div className="space-y-6">
            <div className="bg-white rounded-lg p-6 shadow-sm">
              <div className="flex items-start gap-6">
                <div className="flex-1">
                  <div className="flex justify-between">
                    <div>
                      <h1 className="text-2xl font-semibold text-gray-900">
                        {selectedTherapist.name}
                        <span className="">
                          {" "}
                          {selectedTherapist.designation
                            ? "(" + selectedTherapist.designation + ")"
                            : ""}
                        </span>
                      </h1>
                    </div>
                  </div>
                  <p className="mt-4 text-gray-700">{selectedTherapist.bio}</p>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center">
                    <Award className="w-5 h-5 mr-2 text-blue-600" />
                    Qualifications
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <ul className="space-y-2">
                    {selectedTherapist.qualification &&
                      JSON.parse(selectedTherapist.qualification).map(
                        (qual, index) => {
                          return (
                            <li key={index} className="flex items-start">
                              <span className="text-blue-600 mr-2">•</span>
                              {qual}
                            </li>
                          );
                        }
                      )}
                  </ul>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center">
                    <Shield className="w-5 h-5 mr-2 text-green-600" />
                    Insurance Accepted
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <ul className="space-y-2">
                    {selectedTherapist.insurance_accepted &&
                      JSON.parse(selectedTherapist.insurance_accepted).map(
                        (insurance, index) => {
                          return (
                            <li key={index} className="flex items-start">
                              <span className="text-green-600 mr-2">•</span>
                              {insurance}
                            </li>
                          );
                        }
                      )}
                  </ul>
                </CardContent>
              </Card>
            </div>

            <Tabs defaultValue="clients" className="w-full">
              <TabsList className="bg-white">
                <TabsTrigger value="clients">
                  <Users className="w-4 h-4 mr-2" /> Active Clients
                </TabsTrigger>
                <TabsTrigger value="availability">
                  <Calendar className="w-4 h-4 mr-2" /> Availability
                </TabsTrigger>
              </TabsList>

              <TabsContent value="clients">
                {renderClientsList(selectedTherapist)}
              </TabsContent>

              <TabsContent value="availability">
                {renderAvailability(selectedTherapist)}
              </TabsContent>
            </Tabs>
          </div>
        ) : (
          <div className="flex items-center justify-center h-64 bg-white rounded-lg p-8">
            <p className="text-gray-500">
              Select a therapist to view their profile
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamMember;
