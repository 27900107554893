import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import CustomDataGridToolbar from "../../components/CustomDataGridToolBar/CustomDataGridToolBar";
import { Chip } from "@mui/material";

const TeamList = ({ therapists, handleTherapistSelect }) => {
  const roleOrder = { ADMIN: 1, SUPERVISOR: 2, THERAPIST: 3 };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      editable: false,
      flex: 2,
    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      flex: 2,
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return;
        }

        return (
          <Chip
            label={params.value?.toLowerCase()}
            sx={{
              "& .MuiChip-label": {
                textTransform: "capitalize",
              },
            }}
            color={
              params.value === "ACTIVE"
                ? "success"
                : params.value === "INACTIVE"
                ? "warning"
                : params.value === "INVITED"
                ? "info"
                : "error"
            }
          />
        );
      },
    },
    {
      field: "role_id",
      headerName: "Role",
      editable: false,
      flex: 1,
      valueGetter: (params) => params?.row.role || "", // Extracts the role for sorting
      renderCell: (params) => {
        if (!params.row.role) {
          return;
        }
        const role = params.row.role?.toLowerCase();
        return (
          <Chip
            label={role ? role : "No Role"}
            variant="outlined"
            color={role ? "primary" : "default"}
            sx={{
              width: "10rem",
              "& .MuiChip-label": {
                textTransform: "capitalize",
              },
            }}
          />
        );
      },
      sortComparator: (v1, v2) => {
        return (roleOrder[v1] || 999) - (roleOrder[v2] || 999);
      },
    },
  ];

  return (
    <div className="p-6 bg-gray-50 min-h-screen w-full">
      <div className="mb-8">
        <div className="heading">Teams</div>
        <div className="info-text dashboard-info-text">
          You can check the teams data
        </div>
      </div>
      <div className="w-full">
        <DataGrid
          sx={{
            p: 2,
            backgroundColor: "#ffffff",
            "& .MuiDataGrid-row": {
              transition: "background-color 0.3s ease",
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#f0f0f0", // Light gray background on hover
              cursor: "pointer",
            },
            "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
          }}
          disableRowSelectionOnClick
          getRowId={(row) => row.email}
          onRowClick={(params) => handleTherapistSelect(params.row)}
          rows={therapists}
          columns={columns}
          sortingMode="client"
          slots={{ toolbar: CustomDataGridToolbar }}
          slotProps={{ toolbar: { page: "user" } }}
          pageSizeOptions={[10, 25]}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
          rowHeight={60}
        />
      </div>
    </div>
  );
};

export default TeamList;
